import { createApi } from '@reduxjs/toolkit/query/react'
import AxiosBaseQuery from '../axiosbasedquery'

export const userApi = createApi({
  reducerPath: 'userApi',
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,
  baseQuery: AxiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL
  }),
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ payload }) => ({
        url: 'auth/login',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    socialLogin: builder.mutation({
      query: ({ payload }) => ({
        url: 'auth/socialLogin',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    signupUser: builder.mutation({
      query: ({ payload }) => ({
        url: 'auth/register',
        method: 'POST',
        payload
      })
    }),
    extraInfo: builder.mutation({
      query: ({ payload }) => ({
        url: 'registerStep2',
        method: 'POST',
        payload
      })
    }),
    singleFileUpload: builder.mutation({
      query: ({ payload }) => ({
        url: 'files',
        method: 'POST',
        fileUpload: true,
        isSupportedCoDocs: true,
        payload
      }),
      transformResponse: (response) => response?.data
    }),
    getDataByPan: builder.query({
      query: (panCard) => ({
        url: `checkPan/${panCard}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    forgetPassword: builder.query({
      query: (params) => ({
        url: `mail/reset-pass?email=${params.email}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),

    resetPassword: builder.mutation({
      query: ({ payload }) => ({
        url: 'mail/reset-pass/user/reset',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    getState: builder.query({
      query: (params) => ({
        url: 'states',
        method: 'GET',
        params: {
          apiKey: process.env.REACT_APP_ENV_STATE_API_KEY,
          document: params?.document
        }
      }),
      transformResponse: (response) => response?.data
    }),
    getCity: builder.query({
      query: (params) => ({
        url: 'cityByState',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    verifyCaptchaToken: builder.query({
      query: (token) => ({
        url: `recaptcha/${token}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    verifyOtp: builder.query({
      query: (otp) => ({
        url: `sms/verify?otp=${otp}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    resendOtp: builder.query({
      query: (phone) => ({
        url: `sms/verify/send?to=${phone}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    verifyEmail: builder.query({
      query: (token) => ({
        url: `mail/verify/user/${token}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    resendEmail: builder.query({
      query: (email) => ({
        url: `mail/verify?email=${email}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    getProfile: builder.query({
      query: () => ({
        url: 'user/profile',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    updateProfile: builder.mutation({
      query: ({ payload }) => ({
        url: 'user/update',
        method: 'PUT',
        payload
      }),
      transformResponse: (response) => response
    }),
    changePassword: builder.mutation({
      query: ({ payload }) => ({
        url: 'user/password/update',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    updateNumber: builder.mutation({
      query: (phone) => ({
        url: `user/update-phone?phone=${phone}`,
        method: 'PUT'
      }),
      transformResponse: (response) => response
    }),
    convertDocToPdf: builder.mutation({
      query: ({ payload }) => ({
        url: 'doctopdf/convert',
        method: 'POST',
        payload,
        isUploadDocument: true,
        fileUpload: true
      }),
      transformResponse: (response) => response?.data
    }),
    getAllInvoice: builder.query({
      query: (params) => ({
        url: 'invoice/getUserInvoice',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    getProfilePanDetails: builder.query({
      query: (params) => ({
        url: 'panVerify/getUserPanDetails',
        method: 'GET',
        params
      }),
      transformResponse: (response) => response?.data
    }),
    updatePanDetails: builder.mutation({
      query: ({ payload }) => ({
        url: 'panVerify/editPanRequest',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    sendTwoFactorOtp: builder.query({
      query: () => ({
        url: 'twoFactor/sendCode',
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    verifyTwoFactorOtp: builder.query({
      query: (otp) => ({
        url: `twoFactor/verifyCode?authCode=${otp}`,
        method: 'GET'
      }),
      transformResponse: (response) => response
    }),
    getTermsAndConditions: builder.query({
      query: () => ({
        url: 'termsAndConditions/getTermsAndCondition',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    generateClubbedInvoice: builder.mutation({
      query: () => ({
        url: 'invoice/generateClubbedInvoice',
        method: 'POST'
      }),
      transformResponse: (response) => response
    }),
    logInvoiceDownloadEvent: builder.mutation({
      query: (payload) => ({
        url: 'auditTrail/logEvent',
        method: 'POST',
        payload
      }),
      transformResponse: (response) => response
    }),
    getDowntimeStatus: builder.query({
      query: () => ({
        url: 'downtime/downtime-status',
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    }),
    getDispatchBranches: builder.query({
      query: (stateId) => ({
        url: `dispatchBranch/fetchByState/${stateId}`,
        method: 'GET'
      }),
      transformResponse: (response) => response?.data
    })
  })
})

export const {
  useLoginUserMutation,
  useSignupUserMutation,
  useGetStateQuery,
  useGetCityQuery,
  useVerifyCaptchaTokenQuery,
  useSocialLoginMutation,
  useLazyForgetPasswordQuery,
  useResetPasswordMutation,
  useLazyVerifyOtpQuery,
  useExtraInfoMutation,
  useSingleFileUploadMutation,
  useGetDataByPanQuery,
  useLazyResendOtpQuery,
  useVerifyEmailQuery,
  useLazyResendEmailQuery,
  useGetProfileQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useLazyVerifyCaptchaTokenQuery,
  useUpdateNumberMutation,
  useConvertDocToPdfMutation,
  useGetAllInvoiceQuery,
  useGetProfilePanDetailsQuery,
  useUpdatePanDetailsMutation,
  useLazySendTwoFactorOtpQuery,
  useLazyVerifyTwoFactorOtpQuery,
  useGetTermsAndConditionsQuery,
  useGenerateClubbedInvoiceMutation,
  useLogInvoiceDownloadEventMutation,
  useGetDowntimeStatusQuery,
  useGetDispatchBranchesQuery
} = userApi
