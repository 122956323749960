import dashboard from '../assets/images/dashboard.svg'
import mydocument from '../assets/images/myDocument.svg'
import delivery from '../assets/headerLogo/delivery.svg'
import createdocument from '../assets/images/createDocument.svg'
import manageUser from '../assets/headerLogo/manageStaffUser.svg'
import wallet from '../assets/headerLogo/wallet.svg'
import reports from '../assets/headerLogo/reports.svg'
import invoice from '../assets/headerLogo/invoice.svg'

import help from '../assets/images/help.svg'
import digiLocker from '../assets/images/digiLocker.svg'
import notificationImage from '../assets/images/notificationSiderbar.svg'
// import logo from "../../assets/images/logo.svg";

export const USER_ROLE = {
  INDIVIDUAL: 'Individual',
  CORPORATE: 'Corporate',
  BUSINESS_ASSOCIATE: 'Business associate'
}

export const ROLES = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATE: 'CORPORATE',
  BUSINESS_ASSOCIATE: 'BUSINESS'
}

export const SUB_ROLE = {
  OWNER: 'OWNER',
  STAFF: 'STAFF',
  ADMIN: 'ADMIN'
}

export const CORPORATEGROUPROLES = {
  CORPORATEADMIN: 'corporateadmin',
  CORPORATESTAFF: 'corporatestaff'
}

export const CORPORATE_FILES = {
  POA: 'poa',
  MOA: 'moa',
  AOA: 'aoa',
  BOARD_RESOLUTION: 'br',
  PAN: 'pan',
  SEBI: 'sebi',
  GST: 'gst'
}

export const SIDEBARNO_VISIBLE = {
  SUBSCRIPTION: '/subscription',
  VERIFYSUBSCRIPTION: '/verifySubscription',
  MYPROFILE: '/myProfile'
}

export const SUBSCRIPTION = {
  SUBSCRIPTIONHEADCORPORATE:
        "As a Corporate user, you need to select a subscription plan in order to use platform's services. Below mentioned plans comes with their own benefit, you can choose the one that best suits your requirements. We look forward to onboard you.",
  SUBSCRIPTIONHEADBUSINESS:
        "As a Business Associate user, you need to select a subscription plan in order to use platform's services. Below mentioned plans comes with their own benefit, you can choose the one that best suits your requirements. We look forward to onboard you.",
  RENEWSUBSCRIPTIONHEADCORPORATE:
        'As a corporate user, you have already purchased the selected subscription. You can either continue with the same or can change it to any on of the following',
  RENEWSUBSCRIPTIONHEADBUSINESS:
        'As a Business user, you have already purchased the selected subscription. You can either continue with the same or can change it to any on of the following'
}

export const EXCEPTTHISSYMBOLS = ['e', 'E', '.', '+', '-']

export const IMPORTANTNOTES = [
  '1.)  The information added in these fields should match with the information you have used in the document.',
  '2.) There will be no information added to the uploaded document on the platform. We are taking this information for background processes only.'
]

export const REVIEWCONSENTNOTES = [
  '1.)  The information added in these fields should match with the information you have used in the document.',
  '2.) There will be no information added to the uploaded document on the platform. We are taking this information for background processes only.'
]

export const SIGNATUREPLACEMENTNOTES = [
  'In order to designate the signature placement spot on the document, follow the below listed steps: - ',
  '1.) Click on the icon in the action column in front of the party details. This will activate the placement function and enable the PDF for selection.',
  '2.) Now scroll to the last page of document, where you want the selected party to place their signatures and click on that spot.',
  '3.) This will place a blue box on the selected spot.',
  '4.) Further, you can remove the selection by clicking on the cross icon in action column.',
  '5.) Once all the signature spots placement is complete, the submit button on bottom left of page will get activated. Please submit the page to complete the process.',
  '6.) First Party signatures will be placed on the left side of the pages and Second party signatures will be placed on  the right side of the pages.',
  'NOTE - On bottom of all the pages other than the last page, we will place signatures of all the parties.'
]

export const SIGNATUREPLACEMENTNOTES2 = [
  'How to place e-Sign on the preview ?',
  '1.) Select the party by using the hand icon (image of hand icon)',
  '2.) Place it on preview screen on the right hand side',
  '3.) Repeat this process for all signatories',
  'Please note - Above applies to signature placement on last page only,  for other pages it is auto-assigned.'
]

export const UPLOADFILESACCEPTED = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const UPLOADFILESCORPORATESIGNUPACCEPTED = ['application/pdf', 'image/jpeg', 'image/png']

export const BULKFILESACCEPTED = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/wps-office.xlsx'
]

export const PDFTYPE = 'application/pdf'

export const DOCDOCXTYPE = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const SAMPLEDOWNLOADFILE = [
  {
    'First party name': 'name',
    'First party address': 'address',
    'First party email': 'abc@yopmail.com',
    'First party phone': '9876543210',
    'Second party name': 'Name',
    'Second party address': 'address',
    'Second party email': 'def@yopmail,com',
    'Second party phone': '9876543210',
    'Witness 1 name': 'name',
    'Witness 1 email': 'email',
    'Witness 2 name': 'name',
    'Witness 2 email': 'email',
    'Document Type': 'Physical',
    'Shipping address': 'Address,city,state,pincode',
    'Stamp duty': '100'
  }
]

// const SUB_ROLE = JSON.parse(getSession('user')).subRole;

export const SIDEBAR = [
  {
    title: 'Home',
    logo: dashboard,
    route: '/Home',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Create Document',
    logo: createdocument,
    route: '/createDocument',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'My Document',
    logo: mydocument,
    route: '/myDocument',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Manage Staff Users',
    logo: manageUser,
    route: '/manageStaffUsers',
    roles: [ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN]
  },
  {
    title: 'Wallet & Transaction',
    logo: wallet,
    route: '/walletAndTransactions',
    roles: [ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Payment History',
    logo: invoice,
    route: '/invoice',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Delivery Status',
    logo: delivery,
    route: '/physicalDocument',
    roles: [ROLES?.INDIVIDUAL],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Notifications',
    logo: notificationImage,
    route: '/notifications',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },
  {
    title: 'Help',
    logo: help,
    route: '/help',
    roles: [ROLES?.INDIVIDUAL, ROLES?.CORPORATE, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF]
  },

  {
    title: 'Transfer Documents to DigiLocker',
    logo: digiLocker,
    route: '',
    roles: [ROLES?.INDIVIDUAL, ROLES?.BUSINESS_ASSOCIATE],
    subRoles: [SUB_ROLE?.OWNER, SUB_ROLE?.ADMIN, SUB_ROLE?.STAFF],
    isDigiLockerOption: true
  }
]

export const SIDEBAR_INDIVIDUAL = [
  {
    title: 'Home',
    logo: dashboard,
    route: '/Home'
  },
  {
    title: 'Create Document',
    logo: createdocument,
    route: '/createDocument'
  },
  {
    title: 'My Document',
    logo: mydocument,
    route: '/myDocument'
  },

  {
    title: 'Help',
    logo: help,
    route: '/help'
  }
]

export const SIDEBAR_CORPORATE = [
  {
    title: 'Home',
    logo: dashboard,
    route: '/Home'
  },
  {
    title: 'Create Document',
    logo: createdocument,
    route: '/createDocument'
  },
  {
    title: 'My Document',
    logo: mydocument,
    route: '/myDocument'
  },

  {
    title: 'Manage Staff Users',
    logo: manageUser,
    route: '/manageStaffUsers'
  },
  {
    title: 'Wallet & Transaction',
    logo: wallet,
    route: '/walletAndTransactions'
  },
  {
    title: 'Reports',
    logo: reports,
    route: '/Home'
  },
  {
    title: 'Help',
    logo: help,
    route: '/help'
  }
]

export const SIDEBAR_BUSINESS = [
  {
    title: 'Home',
    logo: dashboard,
    route: '/Home'
  },
  {
    title: 'Create Document',
    logo: createdocument,
    route: '/createDocument'
  },
  {
    title: 'My Document',
    logo: mydocument,
    route: '/myDocument'
  },

  {
    title: 'Manage Staff Users',
    logo: manageUser,
    route: '/manageStaffUsers'
  },
  {
    title: 'Wallet & Transaction',
    logo: wallet,
    route: '/walletAndTransactions'
  },
  {
    title: 'Help',
    logo: help,
    route: '/help'
  }
]

export const PAYMENT_TYPE = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  SINGLEDOCUMENT: 'SINGLE_DOCUMENT',
  BULKDOCUMENT: 'BULK_DOCUMENT',
  WALLET: 'WALLET'
}

export const ORDER_TYPE = {
  DELIVERY: 'Physical',
  DIGITAL: 'Digital',
  HARDCOPY: 'pickup'
}

export const COMMON = {
  RUPEESYMBOL: '₹',
  RUPPES: 'Rs.',
  MULTIPLICATIONSYMBOL: '×'
}

export const PATTERN = {
  NUMBERONLY: /^[1-9][0-9]*$/,
  NUMBERWITHZERO: /^[0-9][0-9]*$/,
  NUMBERWITHTWODECIMALRESTRICT: /^(0|[1-9][0-9]?)(\.[0-9]{0,2})?$/,
  NUMBERWITHSINGLEDECIMALRESTRICT: /^(0|[1-9][0-9]?)(\.[0-9]{0,1})?$/,
  ALPHABETSONLY: /^[A-Za-z\s]+$/,
  ALPHANUMERICDOTONLY: /^[a-zA-Z0-9.]+$/
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

export const PLACESAPICONSTANT = {
  POSTALCODE: 'postal_code',
  STATE: 'administrative_area_level_1',
  CITY: 'administrative_area_level_3'
}

export const PAYMENTSTATUS = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

export const maximumFileSize = {
  file5mb: '5242880',
  file100mb: '104857600'
}

export const STATUSFILTER = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'INACTIVE', name: 'Inactive' }
]

export const CORPORATFILTERROLE = [
  { id: 'ADMIN', name: 'Admin' },
  { id: 'STAFF', name: 'Staff' }
]

export const BUSINESSFILTERROLE = [{ id: 'STAFF', name: 'Staff' }]

export const ENTRYTYPEFILTER = [
  { id: 'CREDIT', name: 'Credit' },
  { id: 'DEBIT', name: 'Debit' }
]

export const SUBSCRIPTIONTYPES = {
  FRESH: 'fresh',
  RENEW: 'renew'
}

export const PROFILESTATUS = {
  PENDING: {
    id: 0,
    name: 'pending'
  },
  REJECTED: {
    id: 1,
    name: 'Rejected'
  },
  APPROVED: {
    id: 2,
    name: 'approved'
  }
}

export const DOCTYPE = {
  PHYSICAL: {
    ID: 1,
    LABEL: 'Physical'
  },
  DIGITAL: {
    ID: 2,
    LABEL: 'Digital'
  },
  HARDCOPY: {
    ID: 3,
    LABEL: 'Hardcopy'
  }
}

export const ERRORCODES = {
  UNAUTHORIZED: {
    STATUSCODE: 401,
    LABEL: 'Unauthorized'
  }
}
export const PARTYTYPES = {
  FIRSTPARTY: {
    ID: 1,
    TITILE: 'First Party'
  },
  SECONDPARTY: {
    ID: 2,
    TITILE: 'Second Party'
  },
  WITNESS: {
    ID: 3,
    TITILE: 'Witness Party'
  }
}

export const TEMPLATEFIELDTYPES = {
  DONOR: {
    ID: 1,
    NAME: 'DONOR'
  },
  DONEE: {
    ID: 2,
    NAME: 'DONEE'
  },
  EXECUTANT: {
    ID: 3,
    NAME: 'EXECUTANT'
  },
  POA_HOLDER: {
    ID: 4,
    NAME: 'POA_HOLDER'
  },
  INDEMNIFIED: {
    ID: 4,
    NAME: 'INDEMNIFIED'
  },
  INDEMNIFIER: {
    ID: 4,
    NAME: 'INDEMNIFIER'
  },
  SECOND_PARTY: {
    ID: 4,
    NAME: 'SECOND_PARTY'
  },
  FIRST_PARTY: {
    ID: 4,
    NAME: 'FIRST_PARTY'
  },
  TENANT: {
    ID: 4,
    NAME: 'TENANT'
  },
  OWNER: {
    ID: 4,
    NAME: 'OWNER'
  },
  BANK: {
    ID: 4,
    NAME: 'BANK'
  },
  CUSTOMER: {
    ID: 4,
    NAME: 'CUSTOMER'
  }
}

export const HELPFAQTYPE = {
  TEXT: {
    ID: 'TEXT',
    label: 'text'
  },
  IMAGE: {
    ID: 'IMAGE',
    label: 'image'
  }
}

export const CLVALIDATIONS = {
  NAME: {
    MINLENGTH: 1,
    MAXLENGTH: 31,
    PATTERN: /^[A-Za-z\s]+$/
  },
  ADDRESS: {
    MINLENGTH: 1,
    MAXLENGTH: 40,
    PATTERN: /^[a-zA-Z0-9 ,.:/-]+$/
  },
  COBAADDRESS: {
    MINLENGTH: 1,
    MAXLENGTH: 80,
    PATTERN: /^[a-zA-Z0-9 ,.:/-]+$/
  },
  DELIVERYNAME: {
    MINLENGTH: 1,
    MAXLENGTH: 40,
    PATTERN: /^[A-Za-z\s]+$/
  },
  ADDRESS_NEW: {
    PATTERN: /^[a-zA-Z0-9 ,.:\-?!@#$()+*;/]+$/
  }
}

export const SIGNERDIGITAL = {
  EXTENSIONLINK: {
    CHROME: 'https://chromewebstore.google.com/detail/signerdigital-digital-sig/glghokcicpikglmflbbelbgeafpijkkf',
    EDGE: 'https://microsoftedge.microsoft.com/addons/detail/signerdigital-digital-si/ogbkcmlmmmllhhinmncdkcieocaameba?hl=en-US'
  },
  HOSTAPPLICATION: {
    WINDOWS:
            'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/239cf047-6948-492d-ba00-df8c516ca16fSigner.Digital.Browser.Extension.Setup.msi',
    LINUX: 'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/0b04d790-6013-48b4-93d9-7f7b986b74baSDChromeExtHost_1.0.0_x64_Ubuntu.zip',
    SUSELINUX:
            'https://digidocblobstorage.blob.core.windows.net/digidoc-dev/44b3d968-f899-46eb-bbda-a08c61861cc3Signer.Digital.Chrome.Extension.Host-1.0.0-1.x86_64.rpm'
  }
}

export const XORKEESIGN = {
  EXTENSIONLINK: {
    CHROME: 'https://chromewebstore.google.com/detail/xorkeesign-india-g2c/plafcfhgmgngohafpaalbpbjifnoaiji?hl=en',
    EDGE: 'https://chromewebstore.google.com/detail/xorkeesign-india-g2c/plafcfhgmgngohafpaalbpbjifnoaiji?hl=en'
  },
  HOSTAPPLICATION: {
    WINDOWS: 'https://www.odysseytec.com/downloads/',
    LINUX: 'https://www.odysseytec.com/downloads/',
    MAQ: 'https://www.odysseytec.com/downloads/'
  }
}

export const PAGINATIONINITIALS = {
  PAGE: 0,
  ROWSPERPAGE: 10
}

export const PANVERIFICATIONSTATUS = {
  NEW: {
    title: 'New',
    id: 'NEW'
  },
  REJECTED: {
    title: 'Rejected',
    id: 'REJECTED'
  },
  REVISION_SUBMITTED: {
    title: 'Revision Submitted',
    id: 'REVISION_SUBMITTED'
  },
  APPROVED: {
    title: 'Approved',
    id: 'APPROVED'
  }
}

export const USERKYCSTATUS = {
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
  REJECTED: 'REJECTED',
  REVISION_SUBMITTED: 'REVISION_SUBMITTED',
  APPROVED: 'APPROVED',
  PAN_NOT_FOUND: 'PAN_NOT_FOUND'
}

export const MDRTERM = {
  MDRCHARGES: {
    title: 'mdrCharges',
    value: 'MDR charges will be added by payment gateway on top of amount shown.'
  }
}

export const BOARDRESOLUTIONTERM = {
  BOARDRESOLUTION: {
    title: 'boardResolution',
    value: '(i) I am authorized to act on behalf of <company name> as per the Board Resolution.(ii)  the Board Resolution issued for performing DigiDoc transactions is in conformity with MoA/AoA of <compnay name>.'
  }
}

export const TERMTYPES = {
  TERMSANDCONDITIONS: {
    title: 'terms and conditions',
    value: 'termsAndConditions'
  },
  PRIVACYPOLICY: {
    title: 'privacy policy',
    value: 'privacyPolicy'
  },
  DISCLAIMER: {
    title: 'disclaimer',
    value: 'disclaimer'
  }
}

export const EVENT_LOG_TYPES = {
  LOGOUT: {
    value: 'LOG_OUT'
  },
  INVOICEDOWNLOAD: {
    value: 'INVOICE_DOWNLOAD'
  }
}
