/* eslint-disable */
import { STATE } from '../constants/stateConstant';
import {
    generateAndamanAndNicobarStampPaper,
    generateAndhraPradeshStampPaper,
    generateAssamStampPaper,
    generateDelhiStampPaper,
    generateBiharStampPaper,
    generateChhattisgarhStampPaper,
    generateKarnatakaStampPaper,
    generateMeghalayaStampPaper,
    generateManipurStampPaper,
    generateOdishaStampPaper,
    generatePunjabStampPaper,
    generateUttarakhandStampPaper,
    generateTripuraStampPaper,
    generateTamilNaduStampPaper,
    generateRajasthanStampPaper,
    generatePuducherryStampPaper,
    generateUttarPradeshStampPaper
} from './stateWiseStampPaper';
import { generateChandigarhStampPaper } from './stateWiseStampPaper/CHANDIGARH/index.js';
import { generateGujaratAboveHundredStampPaper } from './stateWiseStampPaper/GUJARAT/GuajaratAboveHundredStampPaper.js';
import { generateGujaratBelowHundredStampPaper } from './stateWiseStampPaper/GUJARAT/GuajaratBelowHundredStampPaper.js';
import { generateHimachalPradeshStampPaper } from './stateWiseStampPaper/HIMACHALPRADESH/index.js';
import { generateJammuAndKashmirStampPaper } from './stateWiseStampPaper/JAMMUANDKASHMIR/index.js';
import { generateLadakhStampPaper } from './stateWiseStampPaper/LADAKH/index.js';

/**
 *
 * @param {Number} key: StateId
 * @param {Object} extraDetails: Extra information object
 * @returns
 */
export const generateStampPaper = (key, pdf, path, clResponse, extraFieldResponse) => {
    console.log(key);
    switch (key) {
        case STATE?.DELHI?.ID:
            return generateDelhiStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.ANDHRA_PRADESH?.ID:
            return generateAndhraPradeshStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.ANDAMAN_AND_NICOBAR?.ID:
            return generateAndamanAndNicobarStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.ASSAM?.ID:
            return generateAssamStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.BIHAR?.ID:
            return generateBiharStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.CHATTISGARH?.ID:
            return generateChhattisgarhStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.GUJARAT?.ID:
            if (Number(clResponse?.stampDutyAmount) < 100) {
                return generateGujaratBelowHundredStampPaper(
                    pdf,
                    path,
                    clResponse,
                    extraFieldResponse
                );
            }
            return generateGujaratAboveHundredStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.KARNATAKA?.ID:
            return generateKarnatakaStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.MEGHALAYA?.ID:
            return generateMeghalayaStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.MANIPUR?.ID:
            return generateManipurStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.ODISHA?.ID:
            return generateOdishaStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.PUNJAB?.ID:
            return generatePunjabStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.PUDUCHERRY?.ID:
            return generatePuducherryStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.RAJASTHAN?.ID:
            return generateRajasthanStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.TAMILNADU?.ID:
            return generateTamilNaduStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.TRIPURA?.ID:
            return generateTripuraStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.UTTRAKHAND?.ID:
            return generateUttarakhandStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.UTTAR_PRADESH?.ID:
            return generateUttarPradeshStampPaper(pdf, path, clResponse, extraFieldResponse);

        case STATE?.LADAKH?.ID:
            return generateLadakhStampPaper(pdf, path, clResponse, extraFieldResponse);
        case STATE?.HIMACHAL_PRADESH?.ID:
            return generateHimachalPradeshStampPaper(pdf, path, clResponse, extraFieldResponse);
        case STATE?.JAMMU_AND_KASHMIR?.ID:
            return generateJammuAndKashmirStampPaper(pdf, path, clResponse, extraFieldResponse);
        case STATE?.CHANDIGARH?.ID:
            return generateChandigarhStampPaper(pdf, path, clResponse, extraFieldResponse);
        default:
            console.log('validation pending ');
            break;
    }
};

export const getBelowHeadingTitle = (
    extraFieldResponse,
    timesRomanFontBold,
    stateHeadingSize = 15
) => {
    // Check that how much time we just want to repeat small titile heading
    // No of loop depend on the heading text width and small text width
    // Add the initialSmallsubheading till  the remainingWidth is greater than the
    const headingWidth = timesRomanFontBold.widthOfTextAtSize(
        extraFieldResponse?.stateHeading,
        stateHeadingSize
    );
    const stateSmallHeadingInitial = extraFieldResponse?.belowHeadingTitle;
    const belowHeadingWidth = timesRomanFontBold.widthOfTextAtSize(stateSmallHeadingInitial, 2);
    let stateSmallHeading = '';
    for (let i = 0; i < Math.ceil(headingWidth / belowHeadingWidth); i++) {
        const currentSmallHeadingWidth = timesRomanFontBold.widthOfTextAtSize(stateSmallHeading, 2);
        if (currentSmallHeadingWidth < headingWidth) {
            const remainingWidth = headingWidth - currentSmallHeadingWidth;
            if (remainingWidth > belowHeadingWidth) {
                stateSmallHeading += stateSmallHeadingInitial;
            } else {
                stateSmallHeading += stateSmallHeadingInitial.substring(
                    0,
                    Math.floor(remainingWidth / 1.2)
                );
            }
        } else {
            break;
        }
    }
    return stateSmallHeading;
};
