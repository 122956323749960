import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import React from 'react'
import StyledTableCell from '../../../../StyledComponents/TableStyles/TableStyles'
import palette from '../../../../theme/palette'
import CustomTablePagination from '../../../../components/CustomTablePagination/CustomTablePagination'
import PropTypes from 'prop-types'
// import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'

const NotificationTable = ({
  notificationTableData,
  totalCount,
  isLoading,
  setPaginationFilter,
  paginationFilter
}) => {
  // Table headers for each user
  const notificationTableHeaders = ['Notification ID', 'Message', 'Received At']

  // const [backdropState, setBackdropState] = useState(false)
  // if (profile?.role !== ROLES.INDIVIDUAL) {
  //   notificationTableHeaders.unshift('Invoice No.')
  // }

  const handleChangePage = (event, newPage) => {
    setPaginationFilter((prev) => ({
      ...prev,
      page: newPage
    }))

    // setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setPaginationFilter((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }))

    // setRowsPerPage(parseInt(event.target.value, 10))
    // setPage(0)
  }

  return (
        <Paper
            sx={{ width: '100%', overflow: 'hidden', border: `0.5px solid ${palette.text.light}` }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 18rem)' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {notificationTableHeaders.length > 0 &&
                                notificationTableHeaders?.map((HeaderName, index) => (
                                    <StyledTableCell align="left" key={index}>
                                        {HeaderName}
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isLoading &&
                            [0, 1, 2, 3, 4].map((item, index) => (
                                <TableRow key={index}>
                                    {notificationTableHeaders?.map((item, hindex) => (
                                        <StyledTableCell key={hindex}>
                                            <Skeleton />
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            ))}
                        {notificationTableData?.map((invoiceData, index) => (
                            <TableRow key={index}>
                                <StyledTableCell align="left">{invoiceData?.id}</StyledTableCell>
                                <StyledTableCell align="left">
                                    {invoiceData?.message}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                    {invoiceData?.timeOfNotification}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {notificationTableData?.length === 0 && !isLoading && (
                    <Typography
                        variant="modalLabelBold"
                        p={2}
                        sx={{ display: 'flex', justifyContent: 'center' }}>
                        No Data Found
                    </Typography>
                )}
            </TableContainer>

            {/* pagination calling here */}
            <CustomTablePagination
                count={totalCount}
                rowsPerPage={paginationFilter?.rowsPerPage}
                page={paginationFilter?.page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
            {/* {backdropState && <BackdropLoader backdropState={backdropState} />} */}
        </Paper>
  )
}

export default NotificationTable
NotificationTable.propTypes = {
  notificationTableData: PropTypes.array,
  isLoading: PropTypes.Boolean,
  totalCount: PropTypes.number,
  setPaginationFilter: PropTypes?.func,
  paginationFilter: PropTypes?.object
}
