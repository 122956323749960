/* eslint-disable no-unused-vars */
import './Signup.css'
import React, { useState, useEffect, useRef } from 'react'
import {
  Typography,
  Grid,
  Divider,
  TextField,
  Button,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Popover,
  Box
} from '@mui/material'
import gmail from '../../../../assets/images/gmail.svg'
import facebook from '../../../../assets/images/facebook.svg'
import eyeOffIcon from '../../../../assets/images/eyeoff.svg'
import eyeOnIcon from '../../../../assets/images/eyeon.svg'
import flag from '../../../../assets/images/india-flag.svg'
import { validator } from '../../../../util/validator'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { EXCEPTTHISSYMBOLS, PATTERN, TERMTYPES, USER_ROLE } from '../../../../constants/enum'
import ExtraInfoModal from '../ExtraInfoModal/ExtraInfoModal'
import {
  useSignupUserMutation,
  useSocialLoginMutation,
  useLazyVerifyCaptchaTokenQuery
} from '../../../../store/services/userServices'
import OtpScreen from './verifyNumberModal/OtpScreen'
import { setSession } from '../../../../util/helper'
import BackdropLoader from '../../../../components/BackdropLoader/BackdropLoader'
import { socialData } from '../../../../util/socialData'
import { LoginSocialGoogle, LoginSocialFacebook, LoginSocialLinkedin } from 'reactjs-social-login'
import { toast } from 'react-toastify'
import { decodeToken } from '../../../../util/decodeToken'
import errormessages from '../../../../constants/errormessages'
import Root from '../../../../StyledComponents/DividerStyles/DividerStyles'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import palette from '../../../../theme/palette'
import { PLACEHOLDER } from '../../../../constants/placeholder'
import CommonMessageButtonModal from '../../../../components/commonMessageButtonModal/CommonMessageButtonModal'
import {
  ALERT_MESSAGE,
  DOCUMENTMESSAGE,
  INFORMATION_MESSAGE,
  MESSAGE_CONFIRMATION
} from '../../../../constants/message'
import NameAlertModal from '../NameAlertModal/NameAlertModal'
import info from '../../../../assets/images/info.svg'
import CryptoJS from 'crypto-js'
import { Helmet } from 'react-helmet'

const Signup = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Invite token is present in  route if user is invited through the signup link sent on review and consent
  const { inviteToken = '' } = useParams()
  const key = 'lt7i3/JKHPDUtkpfD/Sabw=='

  const { state } = useLocation()

  const [backdropState, setBackdropState] = useState(false)
  // Info-modal state
  const [openInfoModal, setOpenInfoModal] = useState(false)

  // Open otp modal if user enters phone number
  const [openOtpModal, setOpenOtpModal] = useState(false)

  const [termSelectedState, setTermSelecetedState] = useState({})

  const termsArray = [
    {
      termType: TERMTYPES.TERMSANDCONDITIONS.title,
      termId: TERMTYPES.TERMSANDCONDITIONS.value
    },
    {
      termType: TERMTYPES.PRIVACYPOLICY.title,
      termId: TERMTYPES.PRIVACYPOLICY.value
    },
    {
      termType: TERMTYPES.DISCLAIMER.title,
      termId: TERMTYPES.DISCLAIMER.value
    }
  ]

  // otp child counter state
  const [counter, setCounter] = useState(45)
  const [code, setCode] = useState('')

  // Set password type state['text','password']
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [termsConditions, setTermsConditions] = useState(false)
  const [privacyPolicy, setPrivacyPolicy] = useState(false)
  const [disclaimer, setDisclaimer] = useState(false)

  // const [recaptchaVerified, setRecaptchaVerified] = useState(false)

  const captchaRef = useRef(null)

  // Except symbol on number field
  const exceptThisSymbols = EXCEPTTHISSYMBOLS

  // Signup form state
  const [signupForm, setSignupForm] = useState({
    form: {
      fullName: 'Name will be picked from Aadhaar'
    },
    errors: {
      email: 'error',
      password: 'error',
      confirmPassword: 'error',
      phone: 'noerror'
    }
  })

  // Signup mutation
  const [signUp] = useSignupUserMutation({})

  // Social login mutation
  const [socialLogin] = useSocialLoginMutation({})

  const [VerifyCaptchaToken] = useLazyVerifyCaptchaTokenQuery({})
  // const { data: recaptchaVerified } = useVerifyCaptchaTokenQuery(signupForm.recaptchaToken, {
  //     skip: !signupForm.recaptchaToken
  // });

  // Redirect url for social logins
  const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URL_SOCIAL

  // Recaptcha verification state
  const [recaptchaError, setrecaptchError] = useState('')

  // recaptcha object
  // const grecaptchaObject = window.grecaptcha

  // Captcha verification fails reload captcha
  // useEffect(() => {
  //   if (!recaptchaVerified) {
  //     console.log('in if')
  //     grecaptchaObject?.reset()
  //     // setrecaptchError('Verification failed')
  //   }
  //   console.log('useEffecr')
  // }, [recaptchaVerified])

  // User tries to login from social logins and we are unable to fetch the user social details redirect to signup
  useEffect(() => {
    if (state) {
      setSignupForm((prev) => ({
        ...prev,
        form: {
          role: USER_ROLE.INDIVIDUAL,
          email: state?.socialData?.email,
          fullName: state?.socialData?.name,
          provider: state?.socialData?.provider,
          id: state?.socialData?.id
        }
      }))
    } else {
      setSignupForm((prev) => ({
        ...prev,
        form: {
          role: USER_ROLE.INDIVIDUAL,
          provider: 'defaultSignup'
        }
      }))
    }
  }, [state])

  const loadReCaptchaScript = () => {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITEKEY}`
    script.async = true
    script.id = 'recaptcha'
    document.head.appendChild(script)
    return script
  }

  const removeReCaptchaScript = () => {
    // const script = document.querySelector(
    //   'script[src^="https://www.google.com/recaptcha/api.js"]'
    // )

    const script = document.getElementById('recaptcha')
    console.log('hi', script)
    document.head.removeChild(script)
  }

  // Message Modal to inform user regarding his social profile name
  const [messageModal, setMessageModal] = useState({
    open: false,
    provider: '',
    title: ALERT_MESSAGE?.IMPORTANTNOTE,
    message: MESSAGE_CONFIRMATION?.SOCIALLOGINNAME,
    cancelButtonText: 'Cancel',
    submitButtonText: 'Continue'
  })

  // useEffect(() => {
  //   // const loadScriptByURL = (id, url, callback) => {
  //   //   const isScriptExist = document.getElementById(id)
  //   //   const script = document.createElement('script')

  //   //   if (!isScriptExist) {
  //   //     script.type = 'text/javascript'
  //   //     script.src = url
  //   //     script.id = id
  //   //     script.onload = function () {
  //   //       if (callback) callback()
  //   //     }
  //   //     document.body.appendChild(script)
  //   //   }

  //   //   if (isScriptExist && callback) callback()
  //   // }

  //   // // load the script by passing the URL
  //   // loadScriptByURL(
  //   //   'recaptcha-key',
  //   //         `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITEKEY}`,
  //   //         function () {
  //   //           console.log('Script loaded!')
  //   //         }
  //   // )
  //   // loadReCaptchaScript()

  //   return () => {
  //     const script = document.getElementById('recaptcha')
  //     console.log(script)
  //     // if (script) {
  //     script.src = ''
  //     document.head.removeChild(script)

  //     console.log('isexisted', document.getElementById('recaptcha'))
  //     // }
  //   }

  //   // const script = document.createElement('script')
  //   // script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITEKEY}`
  //   // script.async = true
  //   // // script.type = 'text/javascript'
  //   // // script.id = process.env.REACT_APP_CAPTCHA_SITEKEY
  //   // document.body.appendChild(script)

  //   // return () => {
  //   //   console.log('hi')
  //   //   document.body.removeChild(script)
  //   // }
  // }, [])

  // useEffect(() => {
  //   if(){
  //     const script = document.createElement('script')
  //     script.src = 'https://www.google.com/recaptcha/api.js?render=YOUR_SITE_KEY'
  //     script.async = true
  //     document.body.appendChild(script)
  //   }

  //   return () => {
  //     document.body.removeChild(script)
  //   }
  // }, [])

  const handleSignFormSubmit = async () => {
    if (
      signupForm?.errors?.email === 'noerror' &&
            signupForm?.errors?.password === 'noerror' &&
            signupForm?.errors?.confirmPassword === 'noerror' &&
            signupForm?.errors?.phone === 'noerror'
    ) {
      handleSignup()
      // setBackdropState(true)
      // const token = await executeRecaptcha('submit')
      // console.log(token)
      // if (token) {
      //   VerifyCaptchaToken(token)
      //     .unwrap()
      //     .then((response) => {
      //     })
      //     .catch((execption) => {
      //       setBackdropState(false)
      //     })
      // }
    }
  }

  // Invite token decode it and prefill and disable the email field
  useEffect(() => {
    if (inviteToken) {
      const decodedData = decodeToken(inviteToken)
      if (decodedData) {
        setSignupForm((prev) => ({
          ...prev,
          form: {
            email: decodedData?.sub || ''
          },
          errors: {
            ...prev?.errors,
            email: 'noerror'
          }
        }))
        console.log(decodedData)
      } else {
        toast.error(errormessages?.TOKENEXPNOVALID)
      }
      console.log(decodedData)
    }
  }, [inviteToken])

  // Handle recaptcha state
  // const handleReCaptcha = () => {
  //   const token = captchaRef.current.getValue()
  //   if (token) {
  //     VerifyCaptchaToken(token)
  //       .unwrap()
  //       .then((response) => {
  //         setRecaptchaVerified(response?.recaptchaVerified)
  //         if (!response?.recaptchaVerified) {
  //           console.log('in if')
  //           grecaptchaObject?.reset()
  //           setrecaptchError('Verification failed')
  //         } else {
  //           setrecaptchError('noerror')
  //         }
  //       })
  //       .catch((execption) => {})
  //   }
  // }

  /**
     * Handle social Login
     * @param {string} provider:Social login provider[google,linkdln,facebook]
     * @param {object} data : User information object
     */
  const handleSocialLogin = (provider, data) => {
    setBackdropState(true)
    setMessageModal((prev) => ({
      ...prev,
      open: false
    }))
    console.log('provider', provider, data)
    const modifedData = socialData(provider, data)
    socialLogin({ payload: modifedData })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response.message)
        setSession('token', response?.token)
        setSession('user', JSON.stringify(response?.data))
        navigate('/Home', {
          state: {
            HeaderTitle: 'Home'
          }
        })
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('exception:SOcialLOgin:success:LOGIN', execption)
        // toast.error(execption.message)

        setSignupForm((prev) => ({
          ...prev,
          form: {
            role: USER_ROLE.INDIVIDUAL,
            email: modifedData?.email,
            fullName: modifedData?.name,
            provider: modifedData?.provider,
            id: modifedData?.id
          }
        }))
      })
  }

  // Function called on social login click to start loader
  const socialStart = () => {
    console.log('login start')
    setMessageModal((prev) => ({
      ...prev,
      open: true
    }))
    console.log('login start')
    setBackdropState(true)
    setTimeout(() => {
      setBackdropState(false)
    }, 8000)
  }

  /**
     * Change values in signupDetails
     * @param {object} event:event textfield object
     */
  const handleSignupForm = (event) => {
    const err = validator(event.target.name, event.target.value, signupForm?.form.password)
    setSignupForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      },
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))

    if (event.target.name === 'password' && signupForm?.form?.confirmPassword) {
      console.log('errrr', err)
      let ConfirmPassErr
      if (
        signupForm?.form?.confirmPassword &&
                event.target.value !== signupForm?.form?.confirmPassword
      ) {
        ConfirmPassErr = errormessages?.CONFIRMPASSNOMATCH
      } else {
        ConfirmPassErr = signupForm?.form?.confirmPassword
          ? 'noerror'
          : signupForm?.error?.confirmPassword
      }
      setSignupForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          confirmPassword: ConfirmPassErr
        }
      }))
    }
  }

  // Handle signup form submit
  const handleSignup = async () => {
    console.log('Sign Up Form', signupForm?.form)
    const dataToSend = {
      // name: PLACEHOLDER?.PANPICKEDNAME,
      name: PLACEHOLDER?.AADHAARPICKEDNAME,
      email: signupForm?.form?.email,
      password: encrypt(signupForm?.form?.password, key),
      phone: signupForm?.form?.phone,
      token: inviteToken || ''
    }
    setBackdropState(true)

    signUp({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        setSession('token', response?.token)

        if (signupForm?.form?.phone) {
          console.log('in phone')
          setOpenOtpModal(true)
          setCode('')
          setCounter(45)
        } else {
          setOpenInfoModal(true)
        }
      })
      .catch((execption) => {
        setBackdropState(false)
        // toast.error(execption.message)
      })
  }

  // Close otp modal and open extrainfo modal
  const handleverifiedContinue = () => {
    setOpenOtpModal(false)
    setOpenInfoModal(true)
  }

  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }
  const handleContinueClick = () => {
    // close modal
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const encrypt = (password, key) => {
    const encrypted = CryptoJS.AES.encrypt(password, CryptoJS.enc.Utf8.parse(key), {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString()
    return encrypted
  }

  return (
        <Grid item md={6} className="signupRightGrid">
            <Helmet>
                <scrip>
                    {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TM92BW5');`}
                </scrip>
            </Helmet>
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-TM92BW5"
                    height="0"
                    width="0"
                    style={{ display: 'none', visibility: 'hidden' }}></iframe>
            </noscript>
            <div className="signupHead">
                <Typography variant="labelLarge">Signup</Typography>
                {/* Social logins */}
                <div className="socialIcons">
                    <LoginSocialLinkedin
                        client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ''}
                        client_secret={process.env.REACT_APP_LINKEDIN_APP_SECRET || ''}
                        redirect_uri={REDIRECT_URI || ''}
                        scope="r_liteprofile r_emailaddress"
                        onResolve={({ provider, data }) => {
                          setBackdropState(true)
                          handleSocialLogin(provider, data)
                          console.log('Linkedin reponse', provider, data)
                        }}
                        onReject={(err) => {
                          setBackdropState(false)
                          console.log(err)
                        }}>
                        {/* <img
                            alt="linkedln"
                            src={linkedln}
                            className="social-logo"
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              marginRight: '10px',
                              cursor: 'pointer'
                            }}
                        /> */}
                    </LoginSocialLinkedin>
                    <LoginSocialGoogle
                        onClick={socialStart}
                        isOnlyGetCode
                        client_id={process.env.REACT_APP_GG_APP_ID || ''}
                        onResolve={({ provider, data }) => {
                          console.log('hi', data, provider)
                          setBackdropState(true)
                          handleSocialLogin(provider, data)
                        }}
                        scope="openid profile email"
                        onReject={(err) => {
                          console.log(err)
                        }}>
                        <img
                            alt="gmail"
                            src={gmail}
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              marginRight: '10px',
                              cursor: 'pointer'
                            }}
                        />
                    </LoginSocialGoogle>
                    <LoginSocialFacebook
                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                        onResolve={({ provider, data }) => {
                          console.log(provider, data)
                          setBackdropState(true)
                          handleSocialLogin(provider, data)
                        }}
                        onReject={(err) => {
                          console.log(err)
                        }}>
                        <img
                            alt="facebook"
                            src={facebook}
                            style={{
                              borderRadius: '20px',
                              height: '40px',
                              width: '40px',
                              cursor: 'pointer'
                            }}
                        />
                    </LoginSocialFacebook>
                </div>
            </div>

            <Root>
                <Divider className="signupDivider">Or</Divider>
            </Root>

            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleSignFormSubmit(event)
                  // handleSubmit()
                }}>
                <TextField
                    fullWidth
                    placeholder={PLACEHOLDER?.AADHAARPICKEDNAME}
                    value={signupForm?.form?.fullName || ''}
                    id="fullName"
                    name="fullName"
                    label="Full Name"
                    disabled
                    style={{ marginBottom: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      style: {
                        fontWeight: 'bolder' // Apply bold font weight here
                      }
                    }}
                    onChange={(event) => {
                      if (
                        event.target.value === '' ||
                            PATTERN?.ALPHABETSONLY.test(event?.target?.value)
                      ) {
                        handleSignupForm(event)
                      }
                    }}
                />
                <TextField
                    error={
                        !(
                          signupForm?.errors?.email === 'error' ||
                            signupForm?.errors?.email === 'noerror'
                        )
                    }
                    helperText={
                        signupForm?.errors?.email === 'error' ||
                        signupForm?.errors?.email === 'noerror'
                          ? ''
                          : signupForm?.errors?.email
                    }
                    type="email"
                    fullWidth
                    required
                    disabled={Boolean(inviteToken)}
                    value={signupForm?.form?.email || ''}
                    id="email"
                    label="E-mail address"
                    style={{ marginBottom: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="email"
                    onChange={(event) => handleSignupForm(event)}
                    onKeyDown={handlePreventSpace}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            error={
                                !(
                                  signupForm?.errors?.password === 'error' ||
                                    signupForm?.errors?.password === 'noerror'
                                )
                            }
                            helperText={
                                signupForm?.errors?.password === 'error' ||
                                signupForm?.errors?.password === 'noerror'
                                  ? ''
                                  : signupForm?.errors?.password
                            }
                            required
                            name="password"
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            style={{ marginBottom: '20px' }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? (
                                                <img src={eyeOnIcon} alt="Password" />
                                            ) : (
                                                <img src={eyeOffIcon} alt="Password" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            onChange={(event) => handleSignupForm(event)}
                            onKeyDown={handlePreventSpace}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            error={
                                !(
                                  signupForm?.errors?.confirmPassword === 'error' ||
                                    signupForm?.errors?.confirmPassword === 'noerror'
                                )
                            }
                            helperText={
                                signupForm?.errors?.confirmPassword === 'error' ||
                                signupForm?.errors?.confirmPassword === 'noerror'
                                  ? ''
                                  : signupForm?.errors?.confirmPassword
                            }
                            required
                            name="confirmPassword"
                            id="confirmPassword"
                            type={showConfirmPassword ? 'text' : 'password'}
                            label="Confirm Password"
                            style={{ marginBottom: '20px' }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() =>
                                              setShowConfirmPassword(!showConfirmPassword)
                                            }>
                                            {showConfirmPassword ? (
                                                <img src={eyeOnIcon} alt="Password" />
                                            ) : (
                                                <img src={eyeOffIcon} alt="Password" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            onChange={(event) => handleSignupForm(event)}
                            onKeyDown={handlePreventSpace}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <TextField
                    error={
                        !(
                          signupForm?.errors?.phone === 'error' ||
                            signupForm?.errors?.phone === 'noerror'
                        )
                    }
                    helperText={
                        signupForm?.errors?.phone === 'error' ||
                        signupForm?.errors?.phone === 'noerror'
                          ? ''
                          : signupForm?.errors?.phone
                    }
                    fullWidth
                    type="number"
                    id="phoneNumber"
                    name="phone"
                    style={{ marginBottom: '10px' }}
                    label={
                        <span>
                            Phone Number<span style={{ fontStyle: 'italic' }}>(optional)</span>
                        </span>
                    }
                    onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10)
                    }}
                    InputProps={{
                      startAdornment: (
                            <InputAdornment position="start">
                                <img src={flag} alt="flag" />
                                &nbsp;+91 |
                            </InputAdornment>
                      )
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={(event) => handleSignupForm(event)}
                />

                {/* <ReCAPTCHA
                    style={{
                      transform: 'scale(.8)',
                      transformOrigin: 'left'
                    }}
                    // onChange={handleReCaptcha}
                    // grecaptcha={grecaptchaObject}
                    sitekey={process.env.REACT_APP_CAPTCHA_SITEKEY || ''}
                    theme="light"
                    ref={captchaRef}
                />
                {recaptchaError !== 'noerror' && (
                    <span style={{ color: 'red' }}>{recaptchaError}</span>
                )} */}

                {/* <FormControlLabel
                    sx={{ mb: '15px', mr: 0, marginBottom: 0 }}
                    control={
                        <Checkbox
                            size="small"
                            sx={{
                              color: palette.text.main,
                              '&.Mui-checked': {
                                color: palette.success.dark
                              }
                            }}
                            required
                            checked={termsConditions}
                            name="termsConditions"
                            id="termsConditions"
                            onChange={(event) => setTermsConditions(event.target.checked)}
                        />
                    }
                    label={
                        <Typography variant="modalLabel" sx={{ color: palette.text.dark }}>
                            I agree with &nbsp;
                            <Link to="" className="text-link">
                                terms &amp; conditions.
                            </Link>
                        </Typography>
                    }
                />
                <FormControlLabel
                    // sx={{ mb: '15px', mr: 0, marginBottom: 0 }}
                    control={
                        <Checkbox
                            size="small"
                            sx={{
                              color: palette.text.main,
                              '&.Mui-checked': {
                                color: palette.success.dark
                              }
                            }}
                            required
                            checked={privacyPolicy}
                            name="privacyPolicy"
                            id="privacyPolicy"
                            onChange={(event) => setPrivacyPolicy(event.target.checked)}
                        />
                    }
                    label={
                        <Typography variant="modalLabel" sx={{ color: palette.text.dark }}>
                            I agree with &nbsp;
                            <Link to="/privacyPolicy" className="text-link">
                                privacy policy.
                            </Link>
                        </Typography>
                    }
                />
                <FormControlLabel
                    sx={{ mb: '15px', mr: 0 }}
                    control={
                        <Checkbox
                            size="small"
                            sx={{
                              color: palette.text.main,
                              '&.Mui-checked': {
                                color: palette.success.dark
                              }
                            }}
                            required
                            checked={disclaimer}
                            name="disclaimer"
                            id="disclaimer"
                            onChange={(event) => setDisclaimer(event.target.checked)}
                        />
                    }
                    label={
                        <Typography variant="modalLabel" sx={{ color: palette.text.dark }}>
                            I agree with &nbsp;
                            <Link to="/termsAndConditions" className="text-link">
                                Disclaimer.
                            </Link>
                        </Typography>
                    }
                /> */}
                {termsArray.map((item) => (
                    <FormControlLabel
                        sx={{ mr: 0, marginBottom: 0, width: '100%' }}
                        key={item.termType}
                        control={
                            <Checkbox
                                size="small"
                                sx={{
                                  color: palette.text.main,
                                  '&.Mui-checked': {
                                    color: palette.success.dark
                                  }
                                }}
                                required
                                checked={termSelectedState[`${item.termId}`]}
                                name={item.termId}
                                id={item.termId}
                                onChange={(event) =>
                                  setTermSelecetedState((prev) => ({
                                    ...prev,
                                    [event.target.name]: event.target.checked
                                  }))
                                }
                            />
                        }
                        label={
                            <Typography variant="modalLabel" sx={{ color: palette.text.dark }}>
                                I agree with &nbsp;
                                <Link
                                    className="text-link"
                                    to={`/termsAndConditions/${item.termId}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {item.termType}.
                                </Link>
                            </Typography>
                        }
                    />
                ))}
                <Button fullWidth type="submit" variant="primary" sx={{ marginTop: 2 }}>
                    Signup
                </Button>
            </form>

            <div className="signupFoot">
                <Typography variant="modalLabel" sx={{ fontSize: '18px' }}>
                    Already have an account?&nbsp;
                    <Link className="textSignup" to="/">
                        Login
                    </Link>
                </Typography>
            </div>

            {/* Extra information Modal */}
            <ExtraInfoModal
                openInfoModal={openInfoModal}
                handleExtraInfoClose={() => setOpenInfoModal(false)}
            />

            <OtpScreen
                counter={counter}
                setCounter={setCounter}
                code={code}
                setCode={setCode}
                openOtpModal={openOtpModal}
                handleOtpClose={() => setOpenOtpModal(false)}
                phoneNumberSignup={signupForm?.form?.phone}
                handleverifiedContinue={handleverifiedContinue}
            />

            {/* Importnt Information Modal */}
            {messageModal?.open && (
                <NameAlertModal
                    handleOkButtonCommonModal={handleContinueClick}
                    handleCancelButton={() => {
                      setMessageModal((prev) => ({
                        ...prev,
                        open: false
                      }))
                    }}
                    CommonMessageModalState={messageModal}
                    handleSocialLogin={handleSocialLogin}
                />
            )}
            <BackdropLoader backdropState={backdropState} />
        </Grid>
  )
}

export default Signup
