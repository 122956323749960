import React, { useState, useEffect, useRef } from 'react'
import Dropzone from '../../../../components/Dropzone/Dropzone'
import {
  TextField,
  Typography,
  Tab,
  Box,
  Grid,
  Tabs,
  FormControl,
  Select,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  LinearProgress,
  MenuItem,
  Checkbox,
  InputAdornment
} from '@mui/material'
import CloseImg from '../../../../assets/images/closeImg.svg'
import './CorporateTabs.css'
import {
  useGetCityQuery,
  useGetStateQuery,
  useSingleFileUploadMutation,
  useGetDataByPanQuery,
  useGetDispatchBranchesQuery
} from '../../../../store/services/userServices'
import {
  CLVALIDATIONS,
  CORPORATE_FILES,
  PATTERN,
  UPLOADFILESCORPORATESIGNUPACCEPTED,
  maximumFileSize
} from '../../../../constants/enum'
import checked from '../../../../assets/images/checked.svg'
import { validator } from '../../../../util/validator'
import PropTypes from 'prop-types'
import MenuProps from '../../../../StyledComponents/SelectStyles/SelectStyles'
import palette from '../../../../theme/palette'
import { toast } from 'react-toastify'
import errormessages from '../../../../constants/errormessages'
import DatePickerComponent from '../../../../components/DatePicker/DatePickerComponent'
import CustomTooltip from '../../../../components/Tooltip/CustomTooltip'
// import CalendarDate from '../../../../components/CalendarDate/CalendarDate'
// import { toast } from 'react-toastify'

// File name component
function Files ({ myFiles, fileName, removeFile }) {
  return (
        <li style={{ marginRight: '10px' }}>
            <Grid className="uploadFile">
                <Grid>
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, wordBreak: 'break-all' }}>
                        {myFiles[`${fileName}`]?.path}
                    </Typography>
                </Grid>
                <img
                    src={CloseImg}
                    onClick={() => {
                      removeFile(fileName)
                    }}
                    className="removeUploadedFile"
                    alt="Cancel"
                />
            </Grid>
        </li>
  )
}

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

Files.propTypes = {
  myFiles: PropTypes.object,
  fileName: PropTypes.string,
  removeFile: PropTypes.func
}

export default function CorporateTabs ({
  value,
  setValue,
  infoForm,
  setinfoForm,
  setOpenConfirmationModal,
  myFiles,
  setMyFiles
}) {
  const corporateModalTop = useRef()

  // Files uploaded
  // const [myFiles, setMyFiles] = useState({})
  // State tooltip
  const [openStateTooltip, setStateTooltip] = useState(false)
  const handleStateTooltipOpen = () => setStateTooltip(true)
  const handleStateTooltipClose = () => setStateTooltip(false)

  // Next button disable state
  const [nextButton, setnextButton] = useState(false)

  // Set pan data
  const [isPanData, setisPanData] = useState({
    companyName: ''
  })

  // Get pan data information from database query
  const { data: isPanDataApi = {}, isSuccess } = useGetDataByPanQuery(infoForm.form.panCard, {
    skip: infoForm.errors.panCard !== 'noerror'
  })

  // Get all States query
  const { data: stateArray = [] } = useGetStateQuery({})

  // Get all cities according to state selected query
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: infoForm.form.state },
    {
      skip: !infoForm.form.state
    }
  )

  // Get Dispatch Branches according to state selected (only For Corporate User)
  const { data: dispatchBranchesArray = [] } = useGetDispatchBranchesQuery(infoForm.form.state, {
    skip: !infoForm.form.state
  })

  // Single file upload mutation
  const [singleFileUpload] = useSingleFileUploadMutation({})

  // Set pan data in state and infoForm
  useEffect(() => {
    if (Object.keys(isPanDataApi).length > 0 && infoForm.errors.panCard === 'noerror') {
      setisPanData(isPanDataApi)
      setinfoForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          companyName: isPanDataApi?.companyName || '',
          stockBroker: isPanDataApi?.broker ? 'yes' : 'no',
          dateOfIncorporation: isPanDataApi?.dateOfIncorporation || ''
        },
        isPanExist: isPanDataApi?.panExist || false
      }))
    }
  }, [isPanDataApi, isSuccess])

  // Handle next button disable state
  useEffect(() => {
    if (
      !(
        infoForm?.form?.companyName &&
                infoForm?.form?.panCard &&
                infoForm?.moa &&
                infoForm?.poa &&
                infoForm?.aoa &&
                infoForm?.br &&
                infoForm?.pan &&
                infoForm.errors.panCard === 'noerror' &&
                infoForm.errors.contactPerson === 'noerror' &&
                infoForm?.form?.isBoardResolutionAccepted === true
      ) &&
            !isPanData?.panExist
            // && (infoForm.form.stockBroker === 'yes' ? !infoForm?.sebi : '')
    ) {
      setnextButton(true)
    } else {
      // Check if pandata broker is true then open the next button nothing can be done
      if (isPanDataApi?.broker && infoForm.errors.panCard === 'noerror') {
        return setnextButton(false)
      }
      // If in form stock broker true sebi document is compulsory
      if (infoForm?.form?.stockBroker === 'yes' ? !infoForm?.sebi : '') {
        return setnextButton(true)
      }
      if (
        !(
          infoForm.errors.contactPerson === 'noerror' &&
                    infoForm?.form.isBoardResolutionAccepted === true
        )
      ) {
        return setnextButton(true)
      }
      setnextButton(false)
    }
  }, [infoForm])

  /**
     * Handle Uploaded file with validations
     * @param {string} fileName : File name selected
     * @param {Array} acceptedFiles : selected files
     */
  const handleFile = (fileName, acceptedFiles) => {
    const file = acceptedFiles[0]
    const fileType = file.type
    const fileSize = file?.size

    if (!UPLOADFILESCORPORATESIGNUPACCEPTED.includes(fileType)) {
      return toast.error(errormessages?.FILENOTACCEPTED)
    } else if (fileSize > maximumFileSize?.file5mb) {
      return toast.error(errormessages?.FILELARGE)
    } else {
      setMyFiles((prev) => ({
        ...prev,
        [`${fileName}`]: acceptedFiles[0]
      }))

      const data = {
        type: fileName.toUpperCase(),
        file: acceptedFiles[0]
      }

      singleFileUpload({ payload: data })
        .then((response) => {
          setinfoForm((prev) => ({
            ...prev,
            [`${fileName}`]: response?.data?.id
          }))
        })
        .catch((execption) => {
          console.log('exception From:CorporateTabs:SingleFileUpload', execption)
          // toast.error(execption.message)
        })
    }
  }

  /**
     * Handle remove file
     * @param {string} fileName: selected filename to be removed
     */
  const removeFile = (fileName) => {
    console.log(fileName)
    const newState = { ...myFiles }
    delete newState[`${fileName}`]
    setMyFiles(newState)

    const formState = { ...infoForm }
    delete formState[`${fileName}`]
    setinfoForm(formState)
  }

  /**
     * Handle Tab change
     * @param {object} event: Event object
     * @param {number} newValue : Next Tab Value
     */
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  /**
     * Handle infoForm change
     * @param {object} event : Event object
     */
  const handleFormChange = (event) => {
    if (event.target.name === 'state') {
      setinfoForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          dispatchBranch: '',
          city: '',
          [event.target.name]: event.target.value
        }
      }))
    } else {
      const err = validator(event.target.name, event.target.value)
      console.log('Target Value and Error ', event.target.name, event.target.value, err)
      setinfoForm((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [event.target.name]: event.target.value
        },
        errors: {
          ...prev.errors,
          [event.target.name]: err
        }
      }))
    }
  }

  const handleContactPersonNameChange = (event) => {
    const err = validator(event.target.name, event.target.value)
    console.log('Errors inside validator', infoForm.errors)
    setinfoForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [event.target.name]: event.target.value.replace(/\s/gy, '')
      },
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))
  }

  /**
     * Handle Pan data textfield change
     * @param {object} event : Event object
     */
  const handlePanChange = (event) => {
    setisPanData({})
    console.log(event.target.value.length)
    if (event.target.value.length > 10) {
      return event.preventDefault()
    }
    const err = validator(event.target.name, event.target.value)
    setinfoForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        [event.target.name]: event.target.value.toUpperCase()
      },
      errors: {
        ...prev.errors,
        [event.target.name]: err
      }
    }))
    console.log(isPanData)
  }

  const handleDateOfIncorporationChange = (date) => {
    console.log('Pan Date', date)
    setinfoForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        dateOfIncorporation: date
      }
    }))
  }

  const handleBoardResolutionChange = (newValue) => {
    setinfoForm((prev) => ({
      ...prev,
      form: {
        ...prev.form,
        isBoardResolutionAccepted: newValue
      }
    }))
  }

  /**
     * Handle prevent space
     * @param {Object} event: Event object
     */
  const handlePreventSpace = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault()
    }
  }

  return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} marginBottom={5}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    aria-label="basic tabs example"
                    TabIndicatorProps={{
                      style: {
                        background: `linear-gradient(90deg, ${palette.success.main} -21.32%, ${palette.success.light} 125.19%)`,
                        height: '4px'
                      }
                    }}>
                    <Tab label="Step 1" />
                    <Tab label="Step 2" disabled={nextButton} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <TextField
                    error={
                        !(
                          infoForm?.errors?.panCard === 'error' ||
                            infoForm?.errors?.panCard === 'noerror'
                        )
                    }
                    helperText={
                        infoForm?.errors?.panCard === 'error' ||
                        infoForm?.errors?.panCard === 'noerror'
                          ? ''
                          : infoForm?.errors?.panCard
                    }
                    id="PanCard"
                    name="panCard"
                    label="Enter PAN"
                    value={infoForm?.form?.panCard || ''}
                    style={{ marginBottom: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    fullWidth
                    onChange={handlePanChange}
                />
                <DatePickerComponent
                    name="dateOfIncorporation"
                    label="Enter Date Of Incorporation"
                    date={infoForm?.form?.dateOfIncorporation}
                    setDate={handleDateOfIncorporationChange}
                    maxDate={new Date()}
                    disabled={!!isPanData?.dateOfIncorporation}
                />
                <TextField
                    id="companyName"
                    name="companyName"
                    label="Company Name"
                    value={isPanData?.companyName || infoForm?.form?.companyName}
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    required
                    fullWidth
                    onChange={handleFormChange}
                    disabled={!!isPanData?.companyName}
                />
                <TextField
                    id="contactPerson"
                    name="contactPerson"
                    label="Contact Person Name"
                    required
                    value={infoForm?.form?.contactPerson || ''}
                    style={{ marginBottom: '20px' }}
                    type="text"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    onChange={(e) => {
                      if (
                        e.target.value === '' ||
                            PATTERN?.ALPHABETSONLY.test(e?.target?.value)
                      ) {
                        handleContactPersonNameChange(e)
                      }
                    }}
                />
                <Typography variant="modalLabelBold">Upload supporting documents</Typography>
                <Grid marginTop={3}>
                    <Grid container direction="row" spacing={3} sx={{ marginBottom: '20px' }}>
                        {/* {[0, 1, 2].map((item) => (
              <Grid
                lg={6}
                item
                direction="column"
                container
                onClick={() => {
                  setSelectedFile((a) => "moa");
                }}
              >
                <Typography
                  variant="modalLabelSmall"
                  sx={{ marginBottom: "10px" }}
                  component="span"
                >
                  MOA
                </Typography>
                <Dropzone
                  handleFile={handleFile}
                  fileName={CORPORATE_FILES.MOA}
                />
                {infoForm[`${CORPORATE_FILES.MOA}`] && (
                  <ul type="none" className="filesList">
                    <Files
                      fileName={CORPORATE_FILES.MOA}
                      myFiles={myFiles}
                      removeFile={removeFile}
                    />
                  </ul>
                )}
                {!(CORPORATE_FILES.MOA in infoForm) &&
                  CORPORATE_FILES.MOA in myFiles && <LinearProgress />}
              </Grid>
            ))} */}

                        <Grid lg={6} item direction="column" container>
                            <Typography
                                variant="modalLabelSmall"
                                sx={{ marginBottom: '10px' }}
                                component="span">
                                {isPanData?.panExist && (
                                    <img
                                        alt="checked"
                                        src={checked}
                                        className="social-logo"
                                        style={{
                                          height: '11px',
                                          width: '15px'
                                        }}
                                    />
                                )}
                                MOA
                            </Typography>
                            <div className={isPanData?.panExist ? 'is-disabled' : ''}>
                                <Dropzone
                                    handleFile={handleFile}
                                    fileName={CORPORATE_FILES.MOA}
                                    text={'Upload Docs'}
                                />
                            </div>
                            {!isPanData?.panExist && infoForm[`${CORPORATE_FILES.MOA}`] && (
                                <ul type="none" className="filesList">
                                    <Files
                                        fileName={CORPORATE_FILES?.MOA}
                                        myFiles={myFiles}
                                        removeFile={removeFile}
                                    />
                                </ul>
                            )}
                            {!(CORPORATE_FILES?.MOA in infoForm) &&
                                CORPORATE_FILES?.MOA in myFiles && <LinearProgress />}
                        </Grid>
                        <Grid lg={6} item direction="column" container>
                            <Typography
                                variant="modalLabelSmall"
                                sx={{ marginBottom: '10px' }}
                                component="span">
                                {isPanData?.panExist && (
                                    <img
                                        alt="checked"
                                        src={checked}
                                        className="social-logo"
                                        style={{
                                          height: '11px',
                                          width: '15px'
                                        }}
                                    />
                                )}
                                POA
                            </Typography>
                            <div className={isPanData?.panExist ? 'is-disabled' : ''}>
                                <Dropzone
                                    handleFile={handleFile}
                                    fileName={CORPORATE_FILES.POA}
                                    text={'Upload Docs'}
                                />
                            </div>
                            {!isPanData?.panExist && infoForm[`${CORPORATE_FILES.POA}`] && (
                                <ul type="none" className="filesList">
                                    <Files
                                        fileName={CORPORATE_FILES.POA}
                                        myFiles={myFiles}
                                        removeFile={removeFile}
                                    />
                                </ul>
                            )}
                            {!(CORPORATE_FILES?.POA in infoForm) &&
                                CORPORATE_FILES?.POA in myFiles && <LinearProgress />}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={3} sx={{ marginBottom: '20px' }}>
                        <Grid lg={6} item direction="column" container>
                            <Typography variant="modalLabelSmall" sx={{ marginBottom: '10px' }}>
                                {isPanData?.panExist && (
                                    <img
                                        alt="checked"
                                        src={checked}
                                        className="social-logo"
                                        style={{
                                          height: '11px',
                                          width: '15px'
                                        }}
                                    />
                                )}
                                AOA
                            </Typography>
                            <div className={isPanData?.panExist ? 'is-disabled' : ''}>
                                <Dropzone
                                    handleFile={handleFile}
                                    fileName={CORPORATE_FILES.AOA}
                                    text={'Upload Docs'}
                                />
                            </div>
                            {!isPanData?.panExist && infoForm[`${CORPORATE_FILES.AOA}`] && (
                                <ul type="none" className="filesList">
                                    <Files
                                        fileName={CORPORATE_FILES.AOA}
                                        myFiles={myFiles}
                                        removeFile={removeFile}
                                    />
                                </ul>
                            )}
                            {!(CORPORATE_FILES?.AOA in infoForm) &&
                                CORPORATE_FILES?.AOA in myFiles && <LinearProgress />}
                        </Grid>
                        <Grid lg={6} item direction="column" container>
                            <Typography variant="modalLabelSmall" sx={{ marginBottom: '10px' }}>
                                {isPanData?.panExist && (
                                    <img
                                        alt="checked"
                                        src={checked}
                                        className="social-logo"
                                        style={{
                                          height: '11px',
                                          width: '15px'
                                        }}
                                    />
                                )}
                                Board Resolution
                            </Typography>
                            <div className={isPanData?.panExist ? 'is-disabled' : ''}>
                                <Dropzone
                                    handleFile={handleFile}
                                    fileName={CORPORATE_FILES.BOARD_RESOLUTION}
                                    text={'Upload Docs'}
                                />
                            </div>
                            {!isPanData?.panExist &&
                                infoForm[`${CORPORATE_FILES.BOARD_RESOLUTION}`] && (
                                    <ul type="none" className="filesList">
                                        <Files
                                            fileName={CORPORATE_FILES.BOARD_RESOLUTION}
                                            myFiles={myFiles}
                                            removeFile={removeFile}
                                        />
                                    </ul>
                            )}
                            {!(CORPORATE_FILES?.BOARD_RESOLUTION in infoForm) &&
                                CORPORATE_FILES?.BOARD_RESOLUTION in myFiles && <LinearProgress />}
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={3} sx={{ marginBottom: '40px' }}>
                        <Grid lg={6} item direction="column" container>
                            <Typography variant="modalLabelSmall" sx={{ marginBottom: '10px' }}>
                                {isPanData?.panExist && (
                                    <img
                                        alt="checked"
                                        src={checked}
                                        className="social-logo"
                                        style={{
                                          height: '11px',
                                          width: '15px'
                                        }}
                                    />
                                )}
                                PAN
                            </Typography>
                            <div className={isPanData?.panExist ? 'is-disabled' : ''}>
                                <Dropzone
                                    handleFile={handleFile}
                                    fileName={CORPORATE_FILES.PAN}
                                    text={'Upload Docs'}
                                />
                            </div>
                            {!isPanData?.panExist && infoForm[`${CORPORATE_FILES.PAN}`] && (
                                <ul type="none" className="filesList">
                                    <Files
                                        fileName={CORPORATE_FILES.PAN}
                                        myFiles={myFiles}
                                        removeFile={removeFile}
                                    />
                                </ul>
                            )}
                            {!(CORPORATE_FILES?.PAN in infoForm) &&
                                CORPORATE_FILES?.PAN in myFiles && <LinearProgress />}
                        </Grid>
                    </Grid>
                    <Grid lg={6} item direction="column" container sx={{ marginBottom: '20px' }}>
                        <Typography variant="modalLabelBold" sx={{ marginBottom: '10px' }}>
                            Are you a stock broker?
                        </Typography>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="stockBroker"
                                value={infoForm?.form?.stockBroker}
                                onChange={handleFormChange}>
                                <FormControlLabel
                                    value="yes"
                                    disabled={isPanData?.broker}
                                    control={
                                        <Radio
                                            sx={{
                                              color: palette.text.main,
                                              '&.Mui-checked': {
                                                color: palette.success.dark
                                              }
                                            }}
                                        />
                                    }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    value="no"
                                    disabled={isPanData?.broker}
                                    control={
                                        <Radio
                                            sx={{
                                              color: palette.text.main,
                                              '&.Mui-checked': {
                                                color: palette.success.dark
                                              }
                                            }}
                                        />
                                    }
                                    label="No"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    {infoForm.form.stockBroker === 'yes' && (
                        <Grid container direction="row" spacing={3} sx={{ marginBottom: '40px' }}>
                            <Grid lg={6} item direction="column" container>
                                <Typography variant="modalLabelSmall" sx={{ marginBottom: '10px' }}>
                                    {isPanData?.broker && (
                                        <img
                                            alt="checked"
                                            src={checked}
                                            className="social-logo"
                                            style={{
                                              height: '11px',
                                              width: '15px'
                                            }}
                                        />
                                    )}
                                    SEBI
                                </Typography>
                                <div className={isPanData?.broker ? 'is-disabled' : ''}>
                                    <Dropzone
                                        handleFile={handleFile}
                                        fileName={CORPORATE_FILES.SEBI}
                                        text={'Upload Docs'}
                                    />
                                </div>
                                {infoForm[`${CORPORATE_FILES.SEBI}`] && (
                                    <ul type="none" className="filesList">
                                        <Files
                                            fileName={CORPORATE_FILES.SEBI}
                                            myFiles={myFiles}
                                            removeFile={removeFile}
                                        />
                                    </ul>
                                )}
                                {!(CORPORATE_FILES.SEBI in infoForm) &&
                                    CORPORATE_FILES.SEBI in myFiles && <LinearProgress />}
                            </Grid>
                        </Grid>
                    )}

                    <Grid mb={3} mt={2}>
                        <FormControlLabel
                            sx={{ mr: 0, marginBottom: 0, width: '100%' }}
                            control={
                                <Checkbox
                                    sx={{
                                      color: palette.text.main,
                                      '&.Mui-checked': {
                                        color: palette.success.dark
                                      }
                                    }}
                                    checked={infoForm?.form.isBoardResolutionAccepted}
                                    required
                                    onChange={() => {
                                      handleBoardResolutionChange(
                                        !infoForm?.form.isBoardResolutionAccepted
                                      )
                                    }}
                                />
                            }
                            label={
                                <Typography variant="modalLabel" sx={{ color: palette.text.dark }}>
                                    I confirm that &nbsp;(i) I am authorized to act on behalf
                                    of&nbsp;
                                    {infoForm?.form?.companyName} as per the Board Resolution.
                                    <br />
                                    (ii) The Board Resolution issued for performing DigiDoc
                                    transactions is in conformity with MoA/AoA of{' '}
                                    {infoForm?.form?.companyName}.
                                </Typography>
                            }
                        />
                    </Grid>
                    <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item>
                            <Button
                                variant="primary"
                                sx={{ width: '122px', height: '48px' }}
                                onClick={() => setValue(1)}
                                disabled={nextButton}>
                                Next
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="cancelButton"
                                sx={{ width: '122px', height: '48px' }}
                                onClick={() => setOpenConfirmationModal(true)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            {/* Second tab step 2 */}
            <TabPanel value={value} index={1}>
                <Grid container spacing={3} marginBottom={3} ref={corporateModalTop}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <TextField
                            id="CompanyAddress"
                            name="companyAddress"
                            label="Company Address"
                            value={infoForm?.form.companyAddress || ''}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              type: 'text',
                              maxlength: CLVALIDATIONS?.COBAADDRESS?.MAXLENGTH,
                              minlength: CLVALIDATIONS?.COBAADDRESS?.MINLENGTH
                            }}
                            fullWidth
                            onChange={handleFormChange}
                        />
                    </Grid>
                    {/* <Grid item lg={12}>
                        <TextField
                            id="CompanyCode"
                            name="companyCode"
                            label="Company Code"
                            value={infoForm?.form.companyCode || ''}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            onChange={handleFormChange}
                        />
                    </Grid> */}
                    {/* <Grid item lg={12}>
                        <TextField
                            type="date"
                            id="companyRegistrationDate"
                            name="companyRegistrationDate"
                            label="Company Registration Date"
                            value={infoForm?.form.companyRegistrationDate || ''}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            onChange={handleFormChange}
                        />
                    </Grid> */}
                    <Grid item lg={12} sm={12} xs={12}>
                        <TextField
                            type="text"
                            id="companyLandline"
                            name="companyLandline"
                            label="Company Landline Number"
                            inputProps={{
                              type: 'text',
                              // inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxlength: 12,
                              minlength: 12
                            }}
                            value={infoForm?.form.companyLandline || ''}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === '' ||
                                    PATTERN?.NUMBERWITHZERO.test(e?.target?.value)
                              ) {
                                handleFormChange(e)
                              }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={3} marginBottom={3}>
                    <Grid item lg={4} md={4} xs={6}>
                        <FormControl fullWidth>
                            <Select
                                sx={{ fontSize: '16px' }}
                                value={infoForm.form.state || ''}
                                labelId="state"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                name="state"
                                onChange={handleFormChange}
                                MenuProps={MenuProps}>
                                <MenuItem value="" disabled>
                                    <Typography>Select State</Typography>
                                </MenuItem>
                                {stateArray &&
                                    stateArray.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} xs={6}>
                        <FormControl fullWidth>
                            <Select
                                sx={{ fontSize: '16px' }}
                                value={infoForm.form.city || ''}
                                disabled={!infoForm?.form.state}
                                labelId="city"
                                id="city"
                                name="city"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleFormChange}
                                MenuProps={MenuProps}>
                                <MenuItem value="" disabled>
                                    <Typography>Select City</Typography>
                                </MenuItem>
                                {cityArray &&
                                    cityArray.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item lg={4} md={4}>
                        <TextField
                            type="text"
                            value={infoForm?.form.pinCode || ''}
                            id="pinCode"
                            name="pinCode"
                            label="Pin code"
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              type: 'text',
                              // inputMode: 'numeric',
                              pattern: '[0-9]*',
                              maxlength: 6,
                              minlength: 6
                            }}
                            // onKeyDown={(e) =>
                            //   exceptThisSymbols.includes(e.key) && e.preventDefault()
                            // }
                            // onInput={(e) => {
                            //   e.target.value = Math.max(0, parseInt(e.target.value))
                            //     .toString()
                            //     .slice(0, 6)
                            // }}
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === '' ||
                                    PATTERN?.NUMBERONLY.test(e?.target?.value)
                              ) {
                                handleFormChange(e)
                              }
                            }}
                        />
                    </Grid>
                    <Grid item lg={6} md={6}>
                        <FormControl fullWidth>
                            <Select
                                sx={{ fontSize: '16px' }}
                                value={infoForm.form.dispatchBranch || ''}
                                disabled={!infoForm?.form.state}
                                labelId="dispatchBranch"
                                id="dispatchBranch"
                                name="dispatchBranch"
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                onChange={handleFormChange}
                                MenuProps={MenuProps}>
                                <MenuItem value="" disabled>
                                    <Typography>Select Dispatch Branch</Typography>
                                </MenuItem>
                                {dispatchBranchesArray &&
                                    dispatchBranchesArray.map((item) => (
                                        <MenuItem
                                            sx={{ fontSize: '16px' }}
                                            key={item.id}
                                            value={item.id}>
                                            {item.branchName}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            error={
                                !(
                                  infoForm?.errors?.referralEmail === 'error' ||
                                    infoForm?.errors?.referralEmail === 'noerror'
                                )
                            }
                            helperText={
                                infoForm?.errors?.referralEmail === 'error' ||
                                infoForm?.errors?.referralEmail === 'noerror'
                                  ? ''
                                  : infoForm?.errors?.referralEmail
                            }
                            type="email"
                            value={infoForm?.form.referralEmail || ''}
                            id="referralEmail"
                            name="referralEmail"
                            label="Referral Email"
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{
                              endAdornment: (
                                    <InputAdornment position="end">
                                        <CustomTooltip
                                            message={[
                                              'This field is optional. You can contact Stockholding team to get a referral email.'
                                            ]}
                                            openTooltip={openStateTooltip}
                                            setOpenTooltip={setStateTooltip}
                                            handleTooltipOpen={handleStateTooltipOpen}
                                            handleTooltipClose={handleStateTooltipClose}
                                        />
                                    </InputAdornment>
                              )
                            }}
                            placeholder="Enter Refferal Email"
                            fullWidth
                            onChange={handleFormChange}
                            onKeyDown={handlePreventSpace}
                        />
                    </Grid>
                </Grid>
                <Grid item lg={12} marginBottom={3}>
                    <Typography variant="modalLabelBold">Upload supporting documents</Typography>
                </Grid>
                <Grid container direction="row" spacing={3}>
                    <Grid lg={6} item direction="column" container>
                        <Typography variant="modalLabelSmall" sx={{ marginBottom: '10px' }}>
                            GST Registration
                        </Typography>
                        <Dropzone
                            handleFile={handleFile}
                            fileName={CORPORATE_FILES.GST}
                            text={'Upload Docs'}
                        />
                        {infoForm[`${CORPORATE_FILES.GST}`] && (
                            <ul type="none" className="filesList">
                                <Files
                                    fileName={CORPORATE_FILES.GST}
                                    myFiles={myFiles}
                                    removeFile={removeFile}
                                />
                            </ul>
                        )}
                        {!(CORPORATE_FILES.GST in infoForm) && CORPORATE_FILES.GST in myFiles && (
                            <LinearProgress />
                        )}
                    </Grid>
                </Grid>
            </TabPanel>
        </Box>
  )
}

CorporateTabs.propTypes = {
  value: PropTypes.number,
  setValue: PropTypes.func,
  infoForm: PropTypes.object,
  setinfoForm: PropTypes.func,
  setOpenConfirmationModal: PropTypes.func,
  myFiles: PropTypes.object,
  setMyFiles: PropTypes.func
}
