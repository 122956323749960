import React, { useState } from 'react'
import { Box } from '@mui/material'
import { PAGINATIONINITIALS } from '../../constants/enum'
import { useGetAllNotificationsNormalQuery } from '../../store/services/commonServices'
import NotificationTable from './components/NotificationsTable/NotificationTable'
const Notification = () => {
  const intialPaginationFilterState = {
    page: PAGINATIONINITIALS?.PAGE,
    rowsPerPage: PAGINATIONINITIALS?.ROWSPERPAGE
  }

  // Pagination Filter
  const [paginationFilter, setPaginationFilter] = useState(intialPaginationFilterState)

  // Get all document data
  const { data: { notifications = [], totalCount = 0 } = {}, isLoading } =
        useGetAllNotificationsNormalQuery({
          page: paginationFilter?.page,
          size: paginationFilter?.rowsPerPage
        })

  return (
        <Box sx={{ padding: '1% 3%' }}>
            <>
                {/* <Grid
                    container
                    spacing={3}
                    marginBottom={3}
                    direction="row"
                    justifyContent={'center'}
                    alignItems="center">
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <Select
                            name="status"
                            value={filter?.status}
                            onChange={handleStatusChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                              fontSize: '16px'
                            }}
                            MenuProps={MenuProps}
                            fullWidth>
                            {documentStatusArray &&
                                documentStatusArray.map((item, index) => (
                                    <MenuItem key={index} value={item?.id}>
                                        {item?.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <div className="date-range-input-container">
                            <TextField
                                aria-describedby={id}
                                onClick={handleClick}
                                type="text"
                                value={
                                    !filter?.dateRange[0].skip
                                      ? `${filter?.dateRange[0].startDate.toLocaleDateString()} - ${filter?.dateRange[0].endDate.toLocaleDateString()}`
                                      : 'Date Range'
                                }
                                readOnly
                                fullWidth
                            />
                            <img src={calendericon} alt="calender" className="calendar-icon" />
                        </div>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}>
                            <DateRangeComponent
                                maxDate={new Date()}
                                dateRange={filter?.dateRange}
                                setDateRange={dateRangeHandler}
                            />
                        </Popover>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12} sx={{ display: 'flex' }}>
                        <TextField
                            value={filter?.search}
                            name="search"
                            id="search"
                            placeholder={PLACEHOLDER.SEARCHDOCUMENT}
                            onChange={handleSearchTextfield}
                            InputLabelProps={{
                              shrink: false
                            }}
                            InputProps={{
                              style: {
                                borderRadius: '0px'
                              },
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton aria-label="toggle password visibility">
                                            <img src={search} alt="search" />
                                        </IconButton>
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                        />
                        <Button
                            variant="primary"
                            sx={{
                              borderRadius: '1px',
                              padding: '5px',
                              background: palette.text.main,
                              '&:hover': {
                                background: palette.text.main
                              }
                            }}>
                            Go
                        </Button>
                    </Grid>
                </Grid> */}
                <NotificationTable
                    notificationTableData={notifications}
                    isLoading={isLoading}
                    paginationFilter={paginationFilter}
                    setPaginationFilter={setPaginationFilter}
                    totalCount={totalCount}
                />
            </>
        </Box>
  )
}

export default Notification
