/* eslint-disable */
import moment from 'moment';
import { validator } from './validator';
import { CLVALIDATIONS, TEMPLATEFIELDTYPES } from '../constants/enum';

export function validateDocumentSection(sections, form, editedFields) {
    const validationMesaages = [];
    sections.map((field) => {
        field?.subFields?.map((subFields) => {
            if (
                subFields?.props.prefilled &&
                form[`${subFields?.id}`] !== '' &&
                !editedFields?.includes(subFields?.id)
            ) {
                return true;
            }

            // if (!form[`${subFields?.id}`] || form[`${subFields?.id}`] === '') {
            const message = fieldCheck(field.titleText, subFields, form, sections);
            if (message && message !== 'noerror') {
                validationMesaages.push({
                    fieldId: subFields.id,
                    errorMessage: message
                });
            }
            // }
            return true;
        });
        return true;
    });
    return validationMesaages;
}

function fieldCheck(fieldName, subFields, form, allSubFields) {
    /* 
        1.) Updated the check with disabled as for dropdown functionality Lender Details
        2.) Check in the form html , for any element having disabled property to skip validations
    */

    if (subFields?.props?.mandatory === 'true' && !form[`${subFields.id}`]) {
        if (document.getElementById(subFields.id)?.hasAttribute('disabled')) {
            return null;
        }

        return `${fieldName} is required`;
    }

    if (subFields?.props?.mandatory === 'true' || form[`${subFields.id}`]) {
        if (
            Number(subFields?.props.minlength) &&
            form[`${subFields.id}`]?.length < Number(subFields?.props?.minlength)
        ) {
            return `Minimum length ${subFields?.props?.minlength} for  ${fieldName} is required`;
        }
        if (
            Number(subFields?.props?.min_value) &&
            form[`${subFields.id}`] < Number(subFields?.props?.min_value)
        ) {
            return `Minimum Value Should be ${subFields?.props?.min_value} for ${fieldName}.`;
        }
        if (
            Number(subFields?.props.maxlength) &&
            form[`${subFields.id}`]?.length > Number(subFields?.props?.maxlength)
        ) {
            return `Maximum length ${subFields?.props?.maxlength} for  ${fieldName} is required`;
        }
        if (subFields?.props.min_date) {
            const formDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
            const minDate = moment(subFields?.props?.min_date, 'YYYY-MM-DD');

            if (formDate.isBefore(minDate)) {
                return 'Past date is not allowed.';
            }
        }
        if (subFields?.props.max_date) {
            const formDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
            const maxDate = moment(subFields?.props?.max_date, 'YYYY-MM-DD');
            if (formDate.isAfter(maxDate)) {
                return `Please select valid ${fieldName}`;
            }
        }
        if (subFields?.props?.dependent) {
            let dependent = JSON.parse(subFields?.props?.dependent);
            if (Array.isArray(dependent)) {
                const startDate = {
                    date: '',
                    name: ''
                };
                const endDate = {
                    date: '',
                    name: ''
                };
                const lastWorkingDay = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
                // check validation inside each item
                dependent.forEach((item) => {
                    const fieldId = item?.id;

                    // Fetch value from form or fallback to prefilled value from allSubFields
                    const formValue = form[`${fieldId}`];
                    let prefilledValue = '';

                    // If form value is missing, fetch prefilled value
                    if (!formValue) {
                        const prefilledValueSubField = allSubFields
                            .flatMap((subFieldItem) => subFieldItem?.subFields || [])
                            .find((subField) => subField?.id === fieldId);

                        prefilledValue = prefilledValueSubField?.props?.prefilled;
                    }
                    const fieldValue = formValue || prefilledValue;

                    if (item?.validation === 'min_date') {
                        startDate.date = fieldValue ? moment(fieldValue, 'YYYY-MM-DD') : '';
                        startDate.name = item?.value;
                    } else if (item?.validation === 'max_date') {
                        endDate.date = fieldValue ? moment(fieldValue, 'YYYY-MM-DD') : '';
                        endDate.name = item?.value;
                    }
                });

                if (lastWorkingDay.isBefore(startDate.date)) {
                    return `${fieldName} cannot be before ${startDate?.name}`;
                }

                if (lastWorkingDay.isAfter(endDate.date)) {
                    return `${fieldName} cannot be after ${endDate?.name}`;
                }
            } else {
                const dependentId = dependent?.id;
                let prefilledValue = '';
                if (!form[`${dependent?.id}`]) {
                    // Fetch the correct prefilled value for the dependent field
                    const prefilledValueSubField = allSubFields
                        .flatMap((item) => item?.subFields || [])
                        .find((subField) => subField?.id === dependentId);
                    // Fetch the correct prefilled value from subFields
                    prefilledValue = moment(prefilledValueSubField?.props?.prefilled, 'YYYY-MM-DD');
                }

                let dateDifference = '';

                // Calculate the difference in days
                if (dependent?.isEndDate === false) {
                    // if dependent.field_name is end Date , it means it is startDate means isEndDate boolean will come from backend as false, now we can easily map the fieldName in the validation message
                    const startDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');

                    const endDate = form[`${dependent?.id}`]
                        ? moment(form[`${dependent?.id}`], 'YYYY-MM-DD')
                        : prefilledValue;
                    // Validate Start Date: it should not be after End Date
                    dateDifference = endDate.diff(startDate, 'days');

                    if (dateDifference < 0) {
                        return `${fieldName} cannot be after the  ${dependent?.field_name}`;
                    }
                } else {
                    const startDate = form[`${dependent?.id}`]
                        ? moment(form[`${dependent?.id}`], 'YYYY-MM-DD')
                        : prefilledValue;
                    const endDate = moment(form[`${subFields.id}`], 'YYYY-MM-DD');
                    dateDifference = endDate.diff(startDate, 'days');
                    // Check if the end date is before the start date
                    if (dateDifference < 0) {
                        return `${fieldName} cannot be before the ${dependent?.field_name}`;
                    }
                }

                // Check if the difference exceeds 363 days
                if (dateDifference > dependent?.max_date && dateDifference > 1) {
                    // Return or display an error message
                    return `End date should be less than 363 days from the ${dependent?.field_name}`;
                }
            }
        }

        // Max Value validation
        if (subFields?.props.max_value) {
            const decimalValue = parseFloat(form[`${subFields.id}`]);
            if (decimalValue > 3) {
                return `${fieldName} should be less than 3.`;
            }
        }

        // Year validation
        if (subFields?.type === 'date_picker') {
            const formDate = form[`${subFields.id}`];
            const [year] = formDate?.split('-'); // Extract the year part

            // Check year range
            if (year && (parseInt(year, 10) < 1900 || parseInt(year, 10) > 2100)) {
                return `Year must be between 1900 and 2100 for ${fieldName}.`;
            }
        }

        // Validation for address property Address
        if (subFields?.docField === 'PROPERTY_DESCRIPTION') {
            const address = form[`${subFields.id}`];
            if (!CLVALIDATIONS?.ADDRESS_NEW?.PATTERN.test(address)) {
                return `Address must be Alpha Numeric with space.,?:;!@#$()+*-/`;
            }
        }

        const errorMessage = validator(subFields?.type, form[`${subFields.id}`]);
        return errorMessage;
    }
}

/**
 * Function to return Reviewer's to be added in the review consent form according to the fields filled in the template
 *@param {object}totalTemplateReviewerObject: Total Template Fields Object
 *@param {array} partyTypeArray: Party Types for the respective document
 *@return {array} reviewers list
 */
export function provideReviewersList(
    totalTemplateReviewerObject,
    partyTypeArray,
    documentReviewers,
    sectionIndex
) {
    // Finding the object where updation is done
    const sectionObject = Object.keys(totalTemplateReviewerObject).filter(
        (item) => totalTemplateReviewerObject[item]?.index === sectionIndex
    );
    let updatedReviewersList = [...documentReviewers];
    if (sectionObject !== undefined || sectionObject !== '') {
        const reviewerToUpdate = totalTemplateReviewerObject[sectionObject];
        const partyTypeData =
            partyTypeArray.find(
                (item) =>
                    item.name.toLowerCase() ===
                    TEMPLATEFIELDTYPES[sectionObject].NAME.replace(/_/g, ' ').toLowerCase()
            ) || {};

        // Find index of the item in documentReviewer with matching id
        const existingReviewerIndex = updatedReviewersList.findIndex(
            (reviewer) => reviewer.id === reviewerToUpdate.id
        );

        if (existingReviewerIndex >= 0) {
            // Update existing item
            updatedReviewersList[existingReviewerIndex] = {
                ...updatedReviewersList[existingReviewerIndex],
                name: reviewerToUpdate.NAME || updatedReviewersList[existingReviewerIndex].name,
                address:
                    reviewerToUpdate.ADDRESS || updatedReviewersList[existingReviewerIndex].address,
                priority:
                    reviewerToUpdate.priority ||
                    updatedReviewersList[existingReviewerIndex].priority,
                consent:
                    reviewerToUpdate.consent || updatedReviewersList[existingReviewerIndex].consent,
                eSign: reviewerToUpdate.eSign || updatedReviewersList[existingReviewerIndex].eSign,
                email: reviewerToUpdate.email || updatedReviewersList[existingReviewerIndex].email,
                phone: reviewerToUpdate.phone || updatedReviewersList[existingReviewerIndex].phone,
                signMode:
                    reviewerToUpdate.signMode ||
                    updatedReviewersList[existingReviewerIndex].signMode,
                isError:
                    reviewerToUpdate.isError !== undefined
                        ? reviewerToUpdate.isError
                        : updatedReviewersList[existingReviewerIndex].isError
            };
        } else {
            // Add new item to updatedReviewersList if id is not found
            updatedReviewersList.push({
                partyType: partyTypeData, // Assign partyType from the key (e.g., OWNER or TENANT)
                name: reviewerToUpdate.NAME || '',
                address: reviewerToUpdate.ADDRESS || '',
                priority: reviewerToUpdate.priority || '',
                consent: reviewerToUpdate.consent || false,
                eSign: reviewerToUpdate.eSign || false,
                email: reviewerToUpdate.email || '',
                phone: reviewerToUpdate.phone || '',
                signMode: reviewerToUpdate.signMode || 2,
                isError: reviewerToUpdate.isError !== undefined ? reviewerToUpdate.isError : true,
                id: reviewerToUpdate.id // Ensure the unique ID is assigned
            });
        }
    }
    // Return the updated documentReviewer array
    return updatedReviewersList;
}

export function provideReviewerEntriesNoIndex(
    totalTemplateReviewerObject,
    partyTypeArray,
    documentReviewers
) {
    let updatedReviewersList = [...documentReviewers];

    // Iterate over the totalTemplateReviewerObject
    Object.keys(totalTemplateReviewerObject).forEach((templateItem) => {
        const reviewerToUpdate = totalTemplateReviewerObject[templateItem];
        const partyTypeData =
            partyTypeArray.find(
                (item) =>
                    item.name.toLowerCase() ===
                    TEMPLATEFIELDTYPES[templateItem].NAME.replace(/_/g, ' ').toLowerCase()
            ) || {};
        // Find index of the item in documentReviewer with matching id
        const existingReviewerIndex = updatedReviewersList.findIndex(
            (reviewer) => reviewer.id === reviewerToUpdate.id
        );

        if (existingReviewerIndex >= 0) {
            // Update existing item
            updatedReviewersList[existingReviewerIndex] = {
                ...updatedReviewersList[existingReviewerIndex],
                name: reviewerToUpdate.NAME || updatedReviewersList[existingReviewerIndex].name,
                address:
                    reviewerToUpdate.ADDRESS || updatedReviewersList[existingReviewerIndex].address,
                priority:
                    reviewerToUpdate.priority ||
                    updatedReviewersList[existingReviewerIndex].priority,
                consent:
                    reviewerToUpdate.consent || updatedReviewersList[existingReviewerIndex].consent,
                eSign: reviewerToUpdate.eSign || updatedReviewersList[existingReviewerIndex].eSign,
                email: reviewerToUpdate.email || updatedReviewersList[existingReviewerIndex].email,
                phone: reviewerToUpdate.phone || updatedReviewersList[existingReviewerIndex].phone,
                signMode:
                    reviewerToUpdate.signMode ||
                    updatedReviewersList[existingReviewerIndex].signMode,
                isError:
                    reviewerToUpdate.isError !== undefined
                        ? reviewerToUpdate.isError
                        : updatedReviewersList[existingReviewerIndex].isError
            };
        } else {
            // Add new item to updatedReviewersList if id is not found
            updatedReviewersList.push({
                partyType: partyTypeData, // Assign partyType from the key (e.g., OWNER or TENANT)
                name: reviewerToUpdate.NAME || '',
                address: reviewerToUpdate.ADDRESS || '',
                priority: reviewerToUpdate.priority || '',
                consent: reviewerToUpdate.consent || false,
                eSign: reviewerToUpdate.eSign || false,
                email: reviewerToUpdate.email || '',
                phone: reviewerToUpdate.phone || '',
                signMode: reviewerToUpdate.signMode || 2,
                isError: reviewerToUpdate.isError !== undefined ? reviewerToUpdate.isError : true,
                id: reviewerToUpdate.id
            });
        }
    });

    return updatedReviewersList;
}
