import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography
} from '@mui/material'
import Dropzone from '../../../components/Dropzone/Dropzone'
import edit from '../../../assets/images/editIcon.svg'
import flag from '../../../assets/images/india-flag.svg'
import { useNavigate } from 'react-router-dom'
import BankAccountModal from '../../../components/BankAccountModal/BankAccountModal'
import {
  CLVALIDATIONS,
  CORPORATE_FILES,
  EXCEPTTHISSYMBOLS,
  PANVERIFICATIONSTATUS,
  PATTERN
} from '../../../constants/enum'
import {
  useGetCityQuery,
  useGetDispatchBranchesQuery,
  useLazyResendOtpQuery,
  useLazyVerifyOtpQuery,
  useSingleFileUploadMutation,
  useUpdateProfileMutation
} from '../../../store/services/userServices'
import CloseImg from '../../../assets/images/closeImg.svg'
import Root from '../../../StyledComponents/DividerStyles/DividerStyles'
import MenuProps from '../../../StyledComponents/SelectStyles/SelectStyles'
import {
  useEditbankAccountDetailsMutation,
  useGetProfileQuery
} from '../../../store/services/profleServices'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import palette from '../../../theme/palette'
import { CORPBULKPARTY, FILE_STATUS } from '../../../constants/documentConstans'
import { validateFile, validator } from '../../../util/validator'
import { CORPORATECONFIGURATION } from '../../../constants/configurationConstant'
import BackdropLoader from '../../../components/BackdropLoader/BackdropLoader'
import OtpModal from '../../../components/OtpModal/OtpModal'
import DatePickerComponent from '../../../components/DatePicker/DatePickerComponent'
import moment from 'moment'
import info from '../../../assets/images/info.svg'
import { INFORMATION_MESSAGE } from '../../../constants/message'
import { PLACEHOLDER } from '../../../constants/placeholder'

// File name component
function Files ({ myFiles, fileName, removeFile }) {
  return (
        <li style={{ marginRight: '10px' }}>
            <Grid className="uploadFile">
                <Grid>
                    <Typography sx={{ fontSize: '13px', fontWeight: 600, wordBreak: 'break-all' }}>
                        {myFiles[`${fileName}`]?.path}
                    </Typography>
                </Grid>
                <img
                    src={CloseImg}
                    onClick={() => {
                      removeFile(fileName)
                    }}
                    className="removeUploadedFile"
                    alt="Cancel"
                />
            </Grid>
        </li>
  )
}

Files.propTypes = {
  myFiles: PropTypes.object,
  fileName: PropTypes.string,
  removeFile: PropTypes.func
}

const EditProfileCorporate = () => {
  // Navigate
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // Open bank modal state
  const [openBankModal, setOpenBankModal] = useState(false)

  // state for loader
  const [backdropState, setBackdropState] = useState(false)

  // Bank account modal state
  const bankAccountModalState = {
    label: 'Add Bank Account',
    button: 'Save',
    type: 'change'
  }

  const bulkParty = [CORPBULKPARTY?.FIRSTPARTY, CORPBULKPARTY?.SECONDPARTY]

  // update profile form state
  const [updateProfileForm, setupdateProfileForm] = useState({
    errors: {
      panNumber: 'noerror'
    }
  })

  // Get Dispatch Branches according to state selected (only For Corporate User)
  const { data: dispatchBranchesArray = [] } = useGetDispatchBranchesQuery(
    updateProfileForm?.stateId,
    {
      skip: !updateProfileForm?.stateId
    }
  )

  // Files uploaded
  const [myFiles, setMyFiles] = useState({})

  // Update bank account form
  const [addbankAccountForm, setAddbankAccountForm] = useState({
    accountType: '',
    errors: {
      ifscCode: 'error',
      accountNumber: 'error',
      confirmAccountNumber: 'error'
    }
  })

  // Open otp modal if user enters phone number
  const [openOtpModal, setOpenOtpModal] = useState(false)

  // get all city by state id query
  const { data: cityArray = [] } = useGetCityQuery(
    { stateId: updateProfileForm.stateId },
    {
      skip: !updateProfileForm.stateId
    }
  )

  // Edit account details mutation
  const [editbankAccountDetails] = useEditbankAccountDetailsMutation({})

  // Update profile mutation
  const [profileUpdate] = useUpdateProfileMutation({})

  // Single file upload mutation
  const [singleFileUpload] = useSingleFileUploadMutation({})

  const { data: profileData = {}, isSuccess } = useGetProfileQuery({})

  // Profile restrictions for corporate admin and staff
  const profileRestrictionConfiguration = CORPORATECONFIGURATION[`${profileData?.subRole}`]

  console.log('profileRestrictionConfiguration', profileRestrictionConfiguration)
  const exceptThisSymbols = EXCEPTTHISSYMBOLS

  // Resend Otp on phone number change lazyQuery
  const [resendOtp] = useLazyResendOtpQuery()

  // Verify otp lazyQuery
  const [verifyOtp] = useLazyVerifyOtpQuery()

  // otp child counter state
  const [counter, setCounter] = useState(45)

  const [code, setCode] = useState('')

  // Set update profile data form
  useEffect(() => {
    if (profileData && isSuccess) {
      setupdateProfileForm({
        nationalDetails: profileData?.nationalDetails,
        companyDetails: profileData?.companyDetails,
        isBroker: profileData?.nationalDetails?.isBroker ? 'yes' : 'no',
        companyAddress: profileData?.companyDetails?.companyAddress,
        companyLandline: profileData?.companyDetails?.companyLandline,
        name: profileData?.name,
        email: profileData?.email,
        phone: profileData?.phone,
        address: profileData?.address,
        state: profileData?.state?.name,
        stateId: profileData?.state?.id,
        cityId: profileData?.city?.id,
        pinCode: profileData?.pinCode,
        bulkPartyType: profileData?.bulkDetailsResponse?.partyType?.id,
        bulkname: profileData?.bulkDetailsResponse?.name,
        bulkemail: profileData?.bulkDetailsResponse?.email,
        bulkphone: profileData?.bulkDetailsResponse?.phone,
        bulkaddress: profileData?.bulkDetailsResponse?.address,
        panNumber: profileData?.panCardDetails?.panCardNumber,
        dateOfIncorporation: moment(
          profileData?.panCardDetails?.dateOfIncorporation,
          'DD/MM/YYYY'
        ).toDate(),
        companyName: profileData?.companyDetails?.companyName,
        dispatchBranch: profileData?.dispatchBranchResponse?.id,
        errors: {
          panNumber: validator('panCard', profileData?.panCardDetails?.panCardNumber)
        }
      })
      console.log(
        'DISPATCH BRANCHES++++>>>>>>',
        dispatchBranchesArray.filter(
          (item) => item.id === profileData?.dispatchBranchResponse?.id
        ),
        updateProfileForm?.dispatchBranch,
        profileData?.dispatchBranchResponse.id
      )
    }
  }, [profileData, isSuccess])

  /**
     * Handle form change
     * @param {object} event: Event object
     */
  const handleUpdateProfile = (event) => {
    setupdateProfileForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
    console.log('update profile', updateProfileForm)
  }

  /**
     * Handle form change
     * @param {object} event: Event object
     */
  const handleUpdatePanDetails = (event) => {
    const err = validator('panCard', event?.target?.value)

    setupdateProfileForm((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
      errors: {
        ...prev.errors,
        panNumber: err
      }
    }))
  }

  /**
     * Handle form change
     * @param {object} event: Event object
     */
  const handleUpdatePanDateOfIncorporation = (date) => {
    setupdateProfileForm((prev) => ({
      ...prev,
      dateOfIncorporation: date
    }))
  }

  /**
     * Handle form change
     * @param {object} event: Event object
     */
  const handleUpdateCompnayName = (event) => {
    setupdateProfileForm((prev) => ({
      ...prev,
      [event.target.name]: event?.target?.value.replace(/\s/gy, '')
    }))
    console.log('update profile', updateProfileForm)
  }

  /**
     * Handle Uploaded file
     * @param {string} fileName : File name selected
     * @param {Array} acceptedFiles : selected files
     */
  const handleFile = (fileName, acceptedFiles) => {
    console.log('in parent hii')
    const fileError = validateFile(acceptedFiles)
    console.log('acceptedFiles', fileError)
    if (fileError) {
      toast.error(fileError)
    } else {
      setMyFiles((prev) => ({
        ...prev,
        [`${fileName}`]: acceptedFiles[0]
      }))

      const data = {
        type: fileName.toUpperCase(),
        file: acceptedFiles[0]
      }

      singleFileUpload({ payload: data })
        .then((response) => {
          console.log(
            'response From:Profile:SingleFileUpload',
            response,
            response?.data?.id
          )
          setupdateProfileForm((prev) => ({
            ...prev,
            [`${fileName}`]: response?.data?.id
          }))
        })
        .catch((execption) => {
          console.log('exception From:Profile:SingleFileUpload', execption)
          // toast.error(execption.message)
        })
    }
  }

  /**
     * Handle remove file
     * @param {string} fileName: selected filename to be removed
     */
  const removeFile = (fileName) => {
    console.log(fileName)
    const newState = { ...myFiles }
    delete newState[`${fileName}`]
    setMyFiles(newState)

    const formState = { ...updateProfileForm }
    delete formState[`${fileName}`]
    setupdateProfileForm(formState)
  }

  const handleSubmitBankModal = () => {
    const dataToSend = {
      bankName: addbankAccountForm?.bankName,
      accountNumber: addbankAccountForm?.accountNumber,
      accountHolderName: addbankAccountForm?.accountHolderName,
      ifscCode: addbankAccountForm?.ifscCode,
      accountType: addbankAccountForm?.accountType
    }
    if (addbankAccountForm?.errors?.ifscCode === 'noerror') {
      setBackdropState(true)
      editbankAccountDetails({ payload: dataToSend })
        .unwrap()
        .then((response) => {
          setBackdropState(false)
          // Reset form after update
          setAddbankAccountForm({
            accountType: '',
            errors: {
              ifscCode: 'error',
              accountNumber: 'error',
              confirmAccountNumber: 'error'
            }
          })
          toast.success(response?.message)
          setOpenBankModal(false)
        })
        .catch((execption) => {
          setBackdropState(false)
          console.log('exception:ACCOUNT ADD:FAIL', execption)
          // toast.error(execption.message)
        })
    } else {
      setBackdropState(false)
      toast.error('Please fix all errors')
    }
    console.log(updateProfileForm)
  }

  /**
     * Handle update form submit
     * Check user phone number is changed or not accordingly perform actions  Actions:[Verify phone, update profile]
     */
  const handleUpdateFormSubmit = () => {
    console.log(updateProfileForm?.phone, profileData?.phone)
    if (updateProfileForm?.phone !== profileData?.phone) {
      handlePhoneNumberVerify()
    } else {
      handleUpdate()
    }
  }

  // Handle Resend otp
  const handlePhoneNumberVerify = () => {
    setBackdropState(true)
    resendOtp(updateProfileForm?.phone)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response?.message)
        setOpenOtpModal(true)
        setCode('')
        setCounter(45)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('error')
      })
    console.log('phone changed', openOtpModal)
  }

  // handle Verify otp
  const handleVerifyOtp = () => {
    setBackdropState(true)
    verifyOtp(code)
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        toast.success(response.message)
        setOpenOtpModal(false)
        handleUpdate()
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('execption:OTP VERIFICATION')
      })
  }

  // Handle update profile
  const handleUpdate = () => {
    const dataToSend = {
      phone: updateProfileForm?.phone,
      address: updateProfileForm?.address,
      stateId: updateProfileForm?.stateId,
      cityId: updateProfileForm?.cityId,
      pinCode: Number(updateProfileForm?.pinCode),
      moaId: updateProfileForm?.moa,
      aoaId: updateProfileForm?.aoa,
      poaId: updateProfileForm?.poa,
      sebiId: updateProfileForm?.sebi,
      brId: updateProfileForm?.br,
      panId: updateProfileForm?.pan,
      gstId: updateProfileForm?.gst,
      isBroker: updateProfileForm?.isBroker === 'yes',
      companyLandline: updateProfileForm?.companyLandline,
      bulkDetails: {
        partyTypeId: updateProfileForm?.bulkPartyType,
        name: updateProfileForm?.bulkname,
        email: updateProfileForm?.bulkemail,
        address: updateProfileForm?.bulkaddress,
        phone: updateProfileForm?.bulkphone
      },
      dateOfIncorporation: moment(updateProfileForm?.dateOfIncorporation).format(
        'DD-MM-YYYY'
      ),
      companyName: updateProfileForm?.companyName,
      panNumber: updateProfileForm?.panNumber,
      name: updateProfileForm?.name,
      dispatchBranchId: updateProfileForm?.dispatchBranch
    }
    setBackdropState(true)
    profileUpdate({ payload: dataToSend })
      .unwrap()
      .then((response) => {
        setBackdropState(false)
        navigate('/myProfileCorporate', {
          state: {
            HeaderTitle: 'My Profile'
          }
        })
        toast.success(response?.message)
      })
      .catch((execption) => {
        setBackdropState(false)
        console.log('EXCEPTION:CHANGE PASSWORD', execption)
        // toast.error(execption?.message)
      })
  }

  //   for popover
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  console.log(profileData?.bankAccountNumber)
  return (
        <Box p={3}>
            <form
                onSubmit={(event) => {
                  event.preventDefault()
                  handleUpdateFormSubmit()
                }}>
                <Grid container mb={3}>
                    <Grid item>
                        <Typography
                            variant="modalLabelBold"
                            sx={{ fontWeight: 600, fontSize: '18px' }}>
                            User details
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={5} mb={3}>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px', display: 'flex' }}>
                            Name
                            <img
                                style={{ marginLeft: 10 }}
                                src={info}
                                alt="info"
                                onClick={handleClick}
                            />
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right'
                                }}>
                                <Box
                                    sx={{
                                      background: palette.primary.light,
                                      boxShadow: `0px 8px 64px rgba(${palette.sidebar.light}, 0.05), 0px 0px 1px rgba(${palette.sidebar.light}, 0.08)`
                                    }}
                                    className="documentTablePopoverBox">
                                    <Typography
                                        variant="darkValue"
                                        sx={{
                                          letterSpacing: '0.03em',
                                          lineHeight: '30px'
                                        }}>
                                        {INFORMATION_MESSAGE?.NAME_MESSAGE}
                                    </Typography>
                                </Box>
                            </Popover>
                        </Typography>
                        <TextField
                            fullWidth
                            required
                            name="name"
                            id="name"
                            placeholder={PLACEHOLDER?.NAMEASPERAADHAR}
                            value={updateProfileForm?.name}
                            onChange={(event) => {
                              if (
                                event.target.value === '' ||
                                    PATTERN?.ALPHABETSONLY.test(event?.target?.value)
                              ) {
                                handleUpdateProfile(event)
                              }
                            }}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Email address
                        </Typography>
                        <TextField
                            fullWidth
                            required
                            name="email"
                            id="email"
                            disabled
                            value={updateProfileForm?.email}
                        />
                    </Grid>
                    <Grid lg={4} item>
                        <Typography
                            variant="darkValue"
                            component="div"
                            sx={{ marginBottom: '8px' }}>
                            Phone Number
                        </Typography>
                        <TextField
                            required={profileData?.phone}
                            value={updateProfileForm?.phone || ''}
                            type="number"
                            id="phoneNumber"
                            name="phone"
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) && e.preventDefault()
                            }
                            onInput={(e) => {
                              e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 10)
                            }}
                            InputProps={{
                              startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={flag} />
                                        &nbsp;+91 |
                                    </InputAdornment>
                              )
                            }}
                            fullWidth
                            onChange={handleUpdateProfile}
                        />
                    </Grid>
                </Grid>
                {!profileRestrictionConfiguration?.profile?.isNationalDetailsHidden && (
                    <>
                        <Root>
                            <Divider sx={{ marginBottom: '30px' }} />
                        </Root>
                        <Grid container mb={3}>
                            <Grid item>
                                <Typography
                                    variant="modalLabelBold"
                                    sx={{ fontWeight: 600, fontSize: '18px' }}>
                                    National level details of company
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} marginBottom={4}>
                            <Grid item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Company name
                                </Typography>
                                <TextField
                                    fullWidth
                                    required
                                    name="document"
                                    id="document"
                                    disabled
                                    value={updateProfileForm?.nationalDetails?.companyName}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={3}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    MOA
                                </Typography>
                                {updateProfileForm?.nationalDetails?.moa?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.nationalDetails?.moa?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.MOA}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES.MOA}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES.MOA}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES.MOA in updateProfileForm) &&
                                            CORPORATE_FILES.MOA in myFiles && <LinearProgress />}
                                    </>
                                    )}
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    AOA
                                </Typography>
                                {updateProfileForm?.nationalDetails?.aoa?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.nationalDetails?.aoa?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.AOA}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES.AOA}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES.AOA}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES.AOA in updateProfileForm) &&
                                            CORPORATE_FILES.AOA in myFiles && <LinearProgress />}
                                    </>
                                    )}
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    POA
                                </Typography>
                                {updateProfileForm?.nationalDetails?.poa?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.nationalDetails?.poa?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.POA}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES.POA}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES.POA}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES.POA in updateProfileForm) &&
                                            CORPORATE_FILES.POA in myFiles && <LinearProgress />}
                                    </>
                                    )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={3} mt={1}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Board resolution
                                </Typography>
                                {updateProfileForm?.nationalDetails?.br?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.nationalDetails?.br?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.BOARD_RESOLUTION}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[
                                            `${CORPORATE_FILES?.BOARD_RESOLUTION}`
                                        ] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES?.BOARD_RESOLUTION}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(
                                          CORPORATE_FILES?.BOARD_RESOLUTION in updateProfileForm
                                        ) &&
                                            CORPORATE_FILES?.BOARD_RESOLUTION in myFiles && (
                                                <LinearProgress />
                                        )}
                                    </>
                                    )}
                            </Grid>
                            {/* <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Pan number
                                </Typography>
                                <TextField
                                    fullWidth
                                    required
                                    name="document"
                                    id="document"
                                    disabled
                                    value={updateProfileForm?.nationalDetails?.panNumber}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    PAN
                                </Typography>
                                {updateProfileForm?.nationalDetails?.pan?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.nationalDetails?.pan?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.PAN}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES?.PAN}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES?.PAN}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES?.PAN in updateProfileForm) &&
                                            CORPORATE_FILES?.PAN in myFiles && <LinearProgress />}
                                    </>
                                    )}
                            </Grid> */}
                        </Grid>
                        <Grid container spacing={5} mb={4} mt={1}>
                            <Grid item container direction={'column'}>
                                <Typography variant="modalLabelBold" sx={{ marginBottom: '10px' }}>
                                    Are you a stock broker as well?
                                </Typography>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="isBroker"
                                        value={updateProfileForm?.isBroker || 'yes'}
                                        onChange={(e) => {
                                          if (!profileData?.nationalDetails?.isBroker) {
                                            handleUpdateProfile(e)
                                          }
                                        }}>
                                        <FormControlLabel
                                            value="yes"
                                            control={
                                                <Radio
                                                    sx={{
                                                      color: palette.text.main,
                                                      '&.Mui-checked': {
                                                        color: palette.success.dark
                                                      }
                                                    }}
                                                />
                                            }
                                            label="Yes"
                                        />
                                        <FormControlLabel
                                            value="no"
                                            control={
                                                <Radio
                                                    sx={{
                                                      color: palette.text.main,
                                                      '&.Mui-checked': {
                                                        color: palette.success.dark
                                                      }
                                                    }}
                                                />
                                            }
                                            label="No"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <Grid container spacing={5} mb={2}>
                                    {updateProfileForm?.isBroker === 'yes' && (
                                        <>
                                            {updateProfileForm?.nationalDetails?.sebi ? (
                                                <>
                                                    <Grid lg={4} item>
                                                        <Typography
                                                            variant="darkValue"
                                                            component="div"
                                                            sx={{ marginBottom: '8px' }}>
                                                            SEBI
                                                        </Typography>
                                                        {updateProfileForm?.nationalDetails?.sebi
                                                          ?.currentStatus?.id !==
                                                        FILE_STATUS?.REJECTED?.id ? (
                                                            <TextField
                                                                fullWidth
                                                                required
                                                                name="document"
                                                                id="document"
                                                                disabled
                                                                value={
                                                                    updateProfileForm
                                                                      ?.nationalDetails?.sebi
                                                                      ?.fileName
                                                                }
                                                            />
                                                            ) : (
                                                            <>
                                                                <Dropzone
                                                                    handleFile={handleFile}
                                                                    fileName={CORPORATE_FILES?.SEBI}
                                                                    text="Reupload file"
                                                                />
                                                                {updateProfileForm[
                                                                    `${CORPORATE_FILES?.SEBI}`
                                                                ] && (
                                                                    <ul
                                                                        type="none"
                                                                        className="filesList">
                                                                        <Files
                                                                            fileName={
                                                                                CORPORATE_FILES?.SEBI
                                                                            }
                                                                            myFiles={myFiles}
                                                                            removeFile={removeFile}
                                                                        />
                                                                    </ul>
                                                                )}
                                                                {!(
                                                                  CORPORATE_FILES?.SEBI in
                                                                    updateProfileForm
                                                                ) &&
                                                                    CORPORATE_FILES?.SEBI in
                                                                        myFiles && (
                                                                        <LinearProgress />
                                                                )}
                                                            </>
                                                            )}
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid lg={4} item>
                                                        <Dropzone
                                                            handleFile={handleFile}
                                                            fileName={CORPORATE_FILES?.SEBI}
                                                            text="Upload file"
                                                        />
                                                        {updateProfileForm[
                                                            `${CORPORATE_FILES?.SEBI}`
                                                        ] && (
                                                            <ul type="none" className="filesList">
                                                                <Files
                                                                    fileName={CORPORATE_FILES?.SEBI}
                                                                    myFiles={myFiles}
                                                                    removeFile={removeFile}
                                                                />
                                                            </ul>
                                                        )}
                                                        {!(
                                                          CORPORATE_FILES?.SEBI in
                                                            updateProfileForm
                                                        ) &&
                                                            CORPORATE_FILES?.SEBI in myFiles && (
                                                                <LinearProgress />
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Root>
                            <Divider sx={{ marginBottom: '30px' }} />
                        </Root>
                        <Grid container mb={3}>
                            <Grid item>
                                <Typography
                                    variant="modalLabelBold"
                                    sx={{ fontWeight: 600, fontSize: '18px' }}>
                                    PAN details of company
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    PAN
                                </Typography>
                                <TextField
                                    error={
                                        !(
                                          updateProfileForm?.errors?.panNumber === 'error' ||
                                            updateProfileForm?.errors?.panNumber === 'noerror'
                                        )
                                    }
                                    helperText={
                                        updateProfileForm?.errors?.panNumber === 'error' ||
                                        updateProfileForm?.errors?.panNumber === 'noerror'
                                          ? ''
                                          : updateProfileForm?.errors?.panNumber
                                    }
                                    inputProps={{
                                      type: 'text',
                                      maxlength: 10,
                                      minlength: 10
                                    }}
                                    value={updateProfileForm?.panNumber}
                                    required
                                    type="text"
                                    id="panNumber"
                                    name="panNumber"
                                    fullWidth
                                    disabled={
                                        profileData?.panCardDetails?.status !==
                                        PANVERIFICATIONSTATUS.REJECTED.id
                                    }
                                    onChange={handleUpdatePanDetails}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Date Of Incorporation
                                </Typography>
                                <DatePickerComponent
                                    date={updateProfileForm?.dateOfIncorporation}
                                    setDate={handleUpdatePanDateOfIncorporation}
                                    disabled={
                                        profileData?.panCardDetails?.status !==
                                        PANVERIFICATIONSTATUS.REJECTED.id
                                    }
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Company Name
                                </Typography>
                                <TextField
                                    disabled={
                                        profileData?.panCardDetails?.status !==
                                        PANVERIFICATIONSTATUS.REJECTED.id
                                    }
                                    value={updateProfileForm?.companyName || ''}
                                    required
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    fullWidth
                                    onChange={(event) => handleUpdateCompnayName(event)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    PAN Document
                                </Typography>
                                {profileData?.panCardDetails?.pan?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled={
                                            profileData?.panCardDetails?.pan?.currentStatus?.id !==
                                            FILE_STATUS?.REJECTED?.id
                                        }
                                        value={profileData?.panCardDetails?.pan?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.PAN}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES?.PAN}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES?.PAN}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES?.PAN in updateProfileForm) &&
                                            CORPORATE_FILES?.PAN in myFiles && <LinearProgress />}
                                    </>
                                    )}
                            </Grid>
                        </Grid>
                        <Root>
                            <Divider sx={{ marginBottom: '30px' }} />
                        </Root>
                        <Grid container mb={3}>
                            <Grid item>
                                <Typography
                                    variant="modalLabelBold"
                                    sx={{ fontWeight: 600, fontSize: '18px' }}>
                                    State specific details of company
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    State
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.state || ''}
                                    required
                                    type="text"
                                    id="state"
                                    name="state"
                                    fullWidth
                                    disabled
                                    onChange={handleUpdateProfile}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    City
                                </Typography>
                                <Select
                                    disabled
                                    required
                                    value={updateProfileForm?.cityId || ''}
                                    name="cityId"
                                    onChange={handleUpdateProfile}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ fontSize: '16px' }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="" disabled>
                                        <Typography>Select City</Typography>
                                    </MenuItem>

                                    {cityArray &&
                                        cityArray.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Pin code
                                </Typography>
                                <TextField
                                    disabled
                                    value={updateProfileForm?.pinCode || ''}
                                    required
                                    type="number"
                                    id="pinCode"
                                    name="pinCode"
                                    fullWidth
                                    onChange={handleUpdateProfile}
                                    onInput={(e) => {
                                      e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 6)
                                    }}
                                    onKeyDown={(e) =>
                                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Landline Number
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.companyLandline || ''}
                                    required
                                    type="text"
                                    id="landline"
                                    inputProps={{
                                      type: 'text',
                                      // inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                      maxlength: 12,
                                      minlength: 12
                                    }}
                                    name="companyLandline"
                                    fullWidth
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                            PATTERN?.NUMBERWITHZERO.test(e?.target?.value)
                                      ) {
                                        handleUpdateProfile(e)
                                      }
                                    }}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    GST
                                </Typography>
                                <TextField
                                    value={profileData?.companyDetails?.gstNumber || ''}
                                    required
                                    type="text"
                                    id="gst"
                                    name="gst"
                                    disabled
                                    fullWidth
                                    // onChange={handleUpdateProfile}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    GST registration
                                </Typography>
                                {updateProfileForm?.companyDetails?.gst?.currentStatus?.id !==
                                FILE_STATUS?.REJECTED?.id ? (
                                    <TextField
                                        fullWidth
                                        required
                                        name="document"
                                        id="document"
                                        disabled
                                        value={updateProfileForm?.companyDetails?.gst?.fileName}
                                    />
                                    ) : (
                                    <>
                                        <Dropzone
                                            handleFile={handleFile}
                                            fileName={CORPORATE_FILES?.GST}
                                            text="Reupload file"
                                        />
                                        {updateProfileForm[`${CORPORATE_FILES?.GST}`] && (
                                            <ul type="none" className="filesList">
                                                <Files
                                                    fileName={CORPORATE_FILES?.GST}
                                                    myFiles={myFiles}
                                                    removeFile={removeFile}
                                                />
                                            </ul>
                                        )}
                                        {!(CORPORATE_FILES?.GST in updateProfileForm) &&
                                            CORPORATE_FILES?.GST in myFiles && <LinearProgress />}
                                    </>
                                    )}
                                {/* <TextField
                                value={profileData?.companyDetails?.gst?.fileName || ''}
                                required
                                type="text"
                                id="gst"
                                name="gst"
                                fullWidth
                                disabled
                                // onChange={handleUpdateProfile}
                            /> */}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={4}>
                            <Grid item lg={4}>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Dispatch Branch
                                </Typography>
                                <FormControl fullWidth>
                                    <Select
                                        sx={{ fontSize: '16px' }}
                                        value={updateProfileForm?.dispatchBranch || ''}
                                        labelId="dispatchBranch"
                                        id="dispatchBranch"
                                        name="dispatchBranch"
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleUpdateProfile}
                                        required
                                        MenuProps={MenuProps}>
                                        <MenuItem value="" disabled>
                                            <Typography>Select Dispatch Branch</Typography>
                                        </MenuItem>
                                        {dispatchBranchesArray &&
                                            dispatchBranchesArray.map((item) => (
                                                <MenuItem
                                                    sx={{ fontSize: '16px' }}
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.branchName}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={8}>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Company address
                                </Typography>
                                <TextField
                                    disabled
                                    value={updateProfileForm?.companyAddress || ''}
                                    required
                                    type="text"
                                    id="address"
                                    name="address"
                                    multiline
                                    rows={1}
                                    fullWidth
                                    inputProps={{
                                      type: 'text',
                                      maxlength: CLVALIDATIONS?.COBAADDRESS?.MAXLENGTH,
                                      minlength: CLVALIDATIONS?.COBAADDRESS?.MINLENGTH
                                    }}
                                    onChange={handleUpdateProfile}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={3}>
                            <Grid item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Bank account
                                </Typography>
                                {profileData?.bankAccountNumber ? (
                                    <TextField
                                        value={profileData?.bankAccountNumber || ''}
                                        type="text"
                                        id="bankAccount"
                                        name="bankAccount"
                                        fullWidth
                                        // onChange={handleUpdateProfile}
                                        InputProps={{
                                          endAdornment: (
                                                <InputAdornment position="end">
                                                    {profileData?.bankAccountNumber && (
                                                        <img
                                                            src={edit}
                                                            alt="edit"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => setOpenBankModal(true)}
                                                        />
                                                    )}
                                                </InputAdornment>
                                          ),
                                          readOnly: true
                                        }}
                                    />
                                ) : (
                                    <Typography variant="darkValue" sx={{ fontSize: '16px' }}>
                                        No bank account added
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Root>
                            <Divider sx={{ marginBottom: '30px' }} />
                        </Root>
                        <Grid container mb={3}>
                            <Grid item>
                                <Typography
                                    variant="modalLabelBold"
                                    sx={{ fontWeight: 600, fontSize: '18px' }}>
                                    Bulk document details
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Preffered party
                                </Typography>
                                <Select
                                    required
                                    value={updateProfileForm?.bulkPartyType || ''}
                                    name="bulkPartyType"
                                    onChange={handleUpdateProfile}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ fontSize: '16px' }}
                                    MenuProps={MenuProps}
                                    fullWidth>
                                    <MenuItem value="" disabled>
                                        <Typography>Select party</Typography>
                                    </MenuItem>

                                    {bulkParty.length > 0 &&
                                        bulkParty.map((item) => (
                                            <MenuItem
                                                sx={{ fontSize: '16px' }}
                                                key={item.id}
                                                value={item.id}>
                                                {item.title}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Name
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.bulkname || ''}
                                    required
                                    type="text"
                                    id="bulkname"
                                    name="bulkname"
                                    fullWidth
                                    onChange={handleUpdateProfile}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Email address
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.bulkemail || ''}
                                    required
                                    type="email"
                                    id="bulkemail"
                                    name="bulkemail"
                                    fullWidth
                                    onChange={handleUpdateProfile}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} mb={5}>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Phone number
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.bulkphone || ''}
                                    required
                                    type="text"
                                    inputProps={{
                                      type: 'text',
                                      // inputMode: 'numeric',
                                      pattern: '[0-9]*',
                                      maxlength: 10,
                                      minlength: 10
                                    }}
                                    id="bulkphone"
                                    name="bulkphone"
                                    fullWidth
                                    onChange={(e) => {
                                      if (
                                        e.target.value === '' ||
                                            /^[0-9\b]+$/.test(e?.target?.value)
                                      ) {
                                        handleUpdateProfile(e)
                                      }
                                    }}
                                />
                            </Grid>
                            <Grid lg={4} item>
                                <Typography
                                    variant="darkValue"
                                    component="div"
                                    sx={{ marginBottom: '8px' }}>
                                    Address
                                </Typography>
                                <TextField
                                    value={updateProfileForm?.bulkaddress || ''}
                                    required
                                    type="text"
                                    id="bulkaddress"
                                    name="bulkaddress"
                                    fullWidth
                                    onChange={handleUpdateProfile}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
                <Grid>
                    <Button variant="primary" type="submit">
                        Update info
                    </Button>
                </Grid>
            </form>
            {openBankModal && (
                <BankAccountModal
                    bankAccountDetails={addbankAccountForm}
                    setBankAccountDetails={setAddbankAccountForm}
                    bankAccountModalState={bankAccountModalState}
                    openBankModal={openBankModal}
                    handleBankModalClose={() => setOpenBankModal(false)}
                    handleSubmitBankModal={handleSubmitBankModal}
                />
            )}
            {openOtpModal && (
                <OtpModal
                    code={code}
                    setCode={setCode}
                    counter={counter}
                    setCounter={setCounter}
                    phoneNumberUpdate={updateProfileForm?.phone}
                    handleVerifyOtp={handleVerifyOtp}
                    openOtpModal={openOtpModal}
                    handleOtpModalClose={() => setOpenOtpModal(false)}
                />
            )}
            {backdropState && <BackdropLoader backdropState={backdropState} />}
        </Box>
  )
}

export default EditProfileCorporate
