import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { PrivateRoute, PublicRoute, SubscriptionRoute } from './Routes'
import Login from '../pages/landing/components/Login/Login'
import Landing from '../pages/landing'
import Home from '../pages/Home/Home'
import ForgotPassword from '../pages/landing/components/ForgotPassword/ForgotPassword'
import Resetpassword from '../pages/landing/components/ResetPassword/Resetpassword'
import Signup from '../pages/landing/components/Signup/Signup'
import EmailLink from '../pages/landing/components/ForgotPassword/EmailLink/EmailLink'
import MyDocument from '../pages/MyDocument'
import CreateDocument from '../pages/CreateDocument'
import ThankYou from '../pages/CreateDocument/ThankYou/ThankYou'
import DocumentDetails from '../pages/CreateDocument/UploadFlow/DocumentDetails/DocumentDetails'
import Help from '../pages/Help'
import Subscription from '../pages/Subscription/Subscription'
import EmailVerified from '../pages/landing/components/Signup/EmailVerified/EmailVerified'
import NotFound from '../pages/404NotFound/NotFound'
import Profile from '../pages/Profile'
import VerifySubscription from '../pages/Subscription/VerifySubscription/VerifySubscription'
import MyProfile from '../pages/Profile/MyProfile/MyProfile'
import ChangePassword from '../pages/Profile/ChangePassword/ChangePassword'
import EditProfile from '../pages/Profile/EditProfile/EditProfile'
import PaymentSummary from '../pages/CreateDocument/UploadFlow/PaymentSummary/PaymentSummary'
import CustomDocument from '../pages/CreateDocument/CustomFlow/CustomDocument/CustomDocument'
import CommentSection from '../pages/CreateDocument/CustomFlow/ConsentFlow/CommentSection/CommentSection'
import Signature from '../pages/CreateDocument/CustomFlow/ConsentFlow/Signature/Signature'
import Faq from '../pages/Help/Faq/Faq'
import Tickets from '../pages/Help/Tickets/Tickets'
import AddTicket from '../pages/Help/Tickets/AddTicket/AddTicket'
import TicketSummary from '../pages/Help/Tickets/TicketSummary/TicketSummary'
import TermsAndConditions from '../pages/TermsAndConditions/TermsAndConditions'
import ManageStaffUsers from '../pages/ManageStaffUsers/ManageStaffUsers'
import AddNewUserCorporate from '../pages/ManageStaffUsers/AddNewUser/AddnewUserCorporate'
import AddNewUserBusiness from '../pages/ManageStaffUsers/AddNewUser/AddNewUserBusiness'
import ProfileBusiness from '../pages/ProfileBusiness'
import MyProfileBusiness from '../pages/ProfileBusiness/MyProfile/MyProfileBusiness'
import EditProfileBusiness from '../pages/ProfileBusiness/EditProfile/EditProfileBusiness'
import ViewSubscription from '../pages/ProfileBusiness/ViewSubscription/ViewSubscription'
import EditSubscription from '../pages/ProfileBusiness/EditSubscription/EditSubscription'
import AssociatedBranch from '../pages/ProfileBusiness/AssociatedBranch/AssociatedBranch'
import ProfileCorporate from '../pages/ProfileCorporate'
import MyProfileCorporate from '../pages/ProfileCorporate/MyProfile/MyProfileCorporate'
import EditProfileCorporate from '../pages/ProfileCorporate/EditProfile/EditProfileCorporate'
import WalletAndTransaction from '../pages/WalletAndTransactions/WalletAndTransaction'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import EditStaffUserCorporate from '../pages/ManageStaffUsers/EditStaffUser/EditStaffUserCorporate'
import EditStaffUserBusiness from '../pages/ManageStaffUsers/EditStaffUser/EditStaffUserBusiness'
// import IndividualTransaction from '../pages/MyDocument/MyDocumentsCorporate/IndividualTransaction'
import BulkTransaction from '../pages/MyDocument/MyDocumentsCorporate/BulkTransaction'
import RenewSubscription from '../pages/ProfileBusiness/RenewSubscription/RenewSubscription'
import VerifyRenewedSubscription from '../pages/ProfileBusiness/RenewSubscription/VerifyRenewedSubscription/VerifyRenewedSubscription'
import ThankYouScreen from '../pages/Subscription/ThankYouScreen/ThankYouScreen'
import BulkTransactionDocumentDetails from '../pages/MyDocument/MyDocumentsCorporate/BulkTransactionDocumentDetails'
import ViewBulkIndividualDocumentDetail from '../pages/MyDocument/MyDocumentsCorporate/ViewBulkIndividualDocumentDetail/ViewBulkIndividualDocumentDetail'
import ESignaturePage from '../pages/CreateDocument/ESignaturePage/ESignaturePage'
import PreviewScreenHtml from '../pages/MyDocument/CommonPreviewScreen/PreviewScreenHtml/PreviewScreenHtml'
import SignatureRequestedPreview from '../pages/MyDocument/CommonPreviewScreen/SignatureRequestedPreviewScreen/SignatureRequestedPreview'
import BulkSignatureSelection from '../pages/CreateDocument/BulkSignatureSelection/BulkSignatureSelection'
import SessionExpired from '../pages/401SessionExpired/SessionExpired'
import PreviewScreenPdf from '../pages/MyDocument/CommonPreviewScreen/PreviewScreenPdf/PreviewScreenPdf'
import Invoice from '../pages/Invoice/Invoice'
import IndividualPhysicalDocument from '../pages/IndividualBluedart/IndividualPhysicalDocument'
import UnauthorizedAccess from '../pages/511UnauthorizesAccess/UnauthorizedAccess'
// import IndividualMyDocuments from '../pages/MyDocument/MyDocumentIndvidual&Ba/IndividualMyDocument/IndividualMyDocuments'
import CommonIndividualScreen from '../pages/MyDocument/CommonIndividualScreen/CommonIndividualScreen'
import ReviewAndConsent from '../pages/MyDocument/ReviewAndConsent/ReviewAndConsent'
import PanDetails from '../pages/Profile/PanDetails/PanDetails'
import EditPanDetails from '../pages/Profile/EditPanDetails/EditPanDetails'
import EditBusinessPanDetails from '../pages/ProfileBusiness/EditPanDetails/EditBusinessPanDetails'
import BusinessPanDetails from '../pages/ProfileBusiness/PanDetails/BusinessPanDetails'
import About from '../pages/Help/About/About'
import KycAcknowledgement from '../pages/KycAcknowledgement/KycAcknowledgement'
import MultipleCopiesDocument from '../pages/MyDocument/MultipleCopiesDocuments/MultipleCopiesDocument'
import { HelpQuest } from '../pages/helpQuest/htmlPage/HelpQuest'
import { HelpQuestTandC } from '../pages/helpQuest/htmlPage/HelpQuestTandC'
import Notification from '../pages/Notifications/Notification'

// import FCPMetricsComponent from '../pages/CreateDocument/CustomFlow/CustomDocument/FcpMetrics'
// import FCPMetricsComponent1 from '../pages/CreateDocument/CustomFlow/CustomDocument/FcpMetrics1'

// const MyProfile = lazy(() => import('../pages/Profile/MyProfile/MyProfile'))

const Routing = () => {
  return (
        <>
            {/* Landing page login and signup */}
            <Routes>
                <Route
                    path="/"
                    element={
                        <PublicRoute>
                            <Landing />
                        </PublicRoute>
                    }>
                    <Route
                        path="/"
                        element={
                            <PublicRoute>
                                <Login />
                            </PublicRoute>
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey="6Ld8Ew8mAAAAAIPh0Kp_R1BqNB9RZIqMz8oSW_Dw">
                                <Signup />
                            </GoogleReCaptchaProvider>
                        }
                    />
                    <Route
                        path="/signup/:inviteToken"
                        element={
                            <GoogleReCaptchaProvider reCaptchaKey="6Ld8Ew8mAAAAAIPh0Kp_R1BqNB9RZIqMz8oSW_Dw">
                                <Signup />
                            </GoogleReCaptchaProvider>
                        }
                    />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password/:token" element={<Resetpassword />} />
                </Route>
                <Route path="/email-Link" element={<EmailLink />} />
                <Route path="/email-verification/:token" element={<EmailVerified />} />

                <Route path="/session-expired" element={<SessionExpired />} />

                <Route path="/unauthorizedDocument-access" element={<UnauthorizedAccess />} />
                {/* Redirect Url  */}
                <Route path="/Response_MeriPehchaan" element={<KycAcknowledgement />} />

                {/* Subscription */}
                <Route
                    path="/subscription"
                    element={
                        <SubscriptionRoute>
                            <Subscription />
                        </SubscriptionRoute>
                    }
                />
                <Route
                    path="/verifySubscription"
                    element={
                        <PrivateRoute>
                            <VerifySubscription />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/paymentSuccessful"
                    element={
                        <PrivateRoute>
                            <ThankYouScreen />
                        </PrivateRoute>
                    }
                />

                {/* <Route
                    path="/fcp"
                    element={
                        // <PrivateRoute>
                        <FCPMetricsComponent />
                        // </PrivateRoute>
                    }
                /> */}

                {/* <Route
                    path="/fcp1"
                    element={
                        // <PrivateRoute>
                        <FCPMetricsComponent1 />
                        // </PrivateRoute>
                    }
                /> */}

                {/* Home */}
                <Route
                    path="/Home"
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />

                {/* profile section and change password */}
                <Route
                    path="/myProfile"
                    element={
                        <PrivateRoute>
                            <Profile />
                        </PrivateRoute>
                    }>
                    <Route path="/myProfile" element={<MyProfile />} />
                    <Route path="/myProfile/editProfile" element={<EditProfile />} />
                    <Route path="/myProfile/changePassword" element={<ChangePassword />} />
                    <Route path="/myProfile/panDetails" element={<PanDetails />} />
                    <Route path="/myProfile/editPanDetails" element={<EditPanDetails />} />
                </Route>

                {/* terms and conditons */}
                <Route path="/termsAndConditions/:termType" element={<TermsAndConditions />} />

                {/* My document */}
                <Route
                    path="/myDocument"
                    element={
                        <PrivateRoute>
                            <MyDocument />
                        </PrivateRoute>
                    }>
                    <Route path="/myDocument" element={<CommonIndividualScreen />} />
                    {/* <Route path="/myDocument" element={<IndividualTransaction />} /> */}
                    <Route path="/myDocument/bulkTransaction" element={<BulkTransaction />} />
                    <Route path="/myDocument/reviewAndConsent" element={<ReviewAndConsent />} />
                    <Route path="/myDocument/multipleCopies" element={<MultipleCopiesDocument />} />
                </Route>

                {/* bulk transaction document details */}
                <Route
                    path="/myDocument/bulkTransaction/bulkDocumentDetails/:bulkId"
                    element={
                        <PrivateRoute>
                            <BulkTransactionDocumentDetails />
                        </PrivateRoute>
                    }
                />
                {/* bulk individual document details */}
                <Route
                    path="/myDocument/bulkTransaction/bulkIndividualDocumentDetails/:documentId"
                    element={
                        <PrivateRoute>
                            <ViewBulkIndividualDocumentDetail />
                        </PrivateRoute>
                    }
                />
                {/* preview document pdf for e sign page */}
                <Route
                    path="/previewEsignPage/:documentId"
                    element={
                        <PrivateRoute>
                            <ESignaturePage />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/previewBulkSignaturePlacement/:documentId"
                    element={
                        <PrivateRoute>
                            <BulkSignatureSelection />
                        </PrivateRoute>
                    }
                />
                {/* preview document Html for consent submitted */}
                <Route
                    path="/previewDocument/:documentId"
                    element={
                        <PrivateRoute>
                            <PreviewScreenHtml />
                        </PrivateRoute>
                    }
                />

                {/* preview document Html for consent submitted */}
                <Route
                    path="/SignatureRequestedPreview/:documentId"
                    element={
                        <PrivateRoute>
                            <SignatureRequestedPreview />
                        </PrivateRoute>
                    }
                />

                {/* Create Document  */}
                <Route
                    path="/createDocument"
                    element={
                        <PrivateRoute>
                            <CreateDocument />
                        </PrivateRoute>
                    }
                />

                {/* Create document upload flow */}
                <Route
                    path="/documentDetails/:documentId"
                    element={
                        <PrivateRoute>
                            <DocumentDetails />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/paymentSummary/:documentId"
                    element={
                        <PrivateRoute>
                            <PaymentSummary />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/thankYou"
                    element={
                        <PrivateRoute>
                            <ThankYou />
                        </PrivateRoute>
                    }
                />

                {/* Create document custom flow */}
                <Route
                    path="/customDocument/:documentId"
                    element={
                        <PrivateRoute>
                            <CustomDocument />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/commentSection/:documentId"
                    element={
                        <PrivateRoute>
                            <CommentSection />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/signature/:documentId"
                    element={
                        <PrivateRoute>
                            <Signature />
                        </PrivateRoute>
                    }
                />

                {/* Help section */}
                <Route
                    path="/help"
                    element={
                        <PrivateRoute>
                            <Help />
                        </PrivateRoute>
                    }>
                    <Route path="/help" element={<About />} />
                    <Route path="/help/faq" element={<Faq />} />
                    <Route path="/help/tickets" element={<Tickets />} />
                </Route>

                <Route
                    path="/addTicket"
                    element={
                        <PrivateRoute>
                            <AddTicket />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/ticketSummary/:ticketId"
                    element={
                        <PrivateRoute>
                            <TicketSummary />
                        </PrivateRoute>
                    }
                />

                {/* Invoice */}
                <Route
                    path="/invoice"
                    element={
                        <PrivateRoute>
                            <Invoice />
                        </PrivateRoute>
                    }
                />

                {/* Delivery status */}
                <Route
                    path="/physicalDocument"
                    element={
                        <PrivateRoute>
                            <IndividualPhysicalDocument />
                        </PrivateRoute>
                    }
                />

                {/* manage staff users */}
                <Route
                    path="/manageStaffUsers"
                    element={
                        <PrivateRoute>
                            <ManageStaffUsers />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/addNewUserBusiness"
                    element={
                        <PrivateRoute>
                            <AddNewUserBusiness />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/editUserBusiness/:userId"
                    element={
                        <PrivateRoute>
                            <EditStaffUserBusiness />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/addNewUserCorporate"
                    element={
                        <PrivateRoute>
                            <AddNewUserCorporate />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/editUserCorporate/:userId"
                    element={
                        <PrivateRoute>
                            <EditStaffUserCorporate />
                        </PrivateRoute>
                    }
                />

                {/* wallet and transactions */}
                <Route
                    path="/walletAndTransactions"
                    element={
                        <PrivateRoute>
                            <WalletAndTransaction />
                        </PrivateRoute>
                    }
                />

                {/* profile for business user */}
                <Route
                    path="/myProfileBusiness"
                    element={
                        <PrivateRoute>
                            <ProfileBusiness />
                        </PrivateRoute>
                    }>
                    <Route path="/myProfileBusiness" element={<MyProfileBusiness />} />
                    <Route
                        path="/myProfileBusiness/editProfile"
                        element={<EditProfileBusiness />}
                    />
                    <Route path="/myProfileBusiness/changePassword" element={<ChangePassword />} />
                    <Route path="/myProfileBusiness/subscription" element={<ViewSubscription />} />
                    <Route
                        path="/myProfileBusiness/editSubscription"
                        element={<EditSubscription />}
                    />
                    <Route
                        path="/myProfileBusiness/associatedBranch"
                        element={<AssociatedBranch />}
                    />
                    <Route path="/myProfileBusiness/panDetails" element={<BusinessPanDetails />} />
                    <Route
                        path="/myProfileBusiness/editPanDetails"
                        element={<EditBusinessPanDetails />}
                    />
                </Route>

                {/* profile for corporate user */}
                <Route
                    path="/myProfileCorporate"
                    element={
                        <PrivateRoute>
                            <ProfileCorporate />
                        </PrivateRoute>
                    }>
                    <Route path="/myProfileCorporate" element={<MyProfileCorporate />} />
                    <Route
                        path="/myProfileCorporate/editProfile"
                        element={<EditProfileCorporate />}
                    />
                    <Route path="/myProfileCorporate/changePassword" element={<ChangePassword />} />
                    <Route path="/myProfileCorporate/subscription" element={<ViewSubscription />} />
                    <Route
                        path="/myProfileCorporate/editSubscription"
                        element={<EditSubscription />}
                    />
                    <Route
                        path="/myProfileCorporate/associatedBranch"
                        element={<AssociatedBranch />}
                    />
                </Route>

                {/* Renew subscription */}
                <Route
                    path="/renewSubscription"
                    element={
                        <PrivateRoute>
                            <RenewSubscription />
                        </PrivateRoute>
                    }
                />

                {/* verify Renew subscription */}
                <Route
                    path="/verifyRenewedSubscription"
                    element={
                        <PrivateRoute>
                            <VerifyRenewedSubscription />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/preview-screen/:id"
                    element={
                        <PrivateRoute>
                            <PreviewScreenPdf />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/notifications"
                    element={
                        <PrivateRoute>
                            <Notification />
                        </PrivateRoute>
                    }
                />

                {/* Random or undeclared path */}
                <Route path="*" element={<NotFound />} />

                <Route path='/privacyPolicyHelpQuest' element= { <HelpQuest />}/>
                <Route path='/termsAndConditionsHelpQuest' element= { <HelpQuestTandC />}/>

            </Routes>
        </>
  )
}

export default Routing
